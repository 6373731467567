@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Quicksand:700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&subset=japanese");
*, *:before, *:after {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  outline: 0;
  padding: 0;
  color: inherit;
  font-size: 100%;
  font: inherit;
  text-decoration: none;
  vertical-align: baseline;
}

article, aside, details, dialog, figcaption, figure, footer, header, main, menu, nav, section {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

body {
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  text-size-adjust: 100%;
}

br, hr {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

input, select {
  vertical-align: middle;
}

img {
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
}

/* --------------------------------------------------
 00:  Utility Style
 -------------------------------------------------- */
.mt0 {
  margin-top: 0px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.pt0 {
  padding-top: 0px !important;
}

.pb0 {
  padding-bottom: 0px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.pt5 {
  padding-top: 5px !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.pt35 {
  padding-top: 35px !important;
}

.pb35 {
  padding-bottom: 35px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.pb45 {
  padding-bottom: 45px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.red {
  color: #b70000;
}

/* display */
@media only screen and (max-width: 768px) {
  .pc-only {
    display: none;
  }
}

.sp-only {
  display: none;
}
@media only screen and (max-width: 768px) {
  .sp-only {
    display: block;
  }
}

html {
  font-size: 100%;
  color: #333;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  font-size: 14px;
  line-height: 1.6;
}
@media screen and (max-width: 768px), print {
  body {
    font-size: 11px;
    overflow-x: hidden;
  }
}

body.en {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
}

body.cn {
  font-family: 'Segoe UI',SegoeUI,'Microsoft YaHei',微软雅黑,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

body.tw {
  font-family: '微軟正黑體', 'Microsoft JhengHei', 'PMingLiU',"Helvetica Neue",Helvetica,Arial,sans-serif;
}

input,
select,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border-radius: 0;
  border: 0;
  font-size: 100%;
  vertical-align: middle;
}

select {
  background-color: transparent;
  text-indent: .01px;
  text-overflow: "";
}

textarea {
  font-size: 100%;
}

em {
  font-style: normal;
}

strong {
  font-weight: bold;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  display: block;
}
@media screen and (max-width: 768px), print {
  img {
    width: 100%;
  }
}

a {
  color: #666666;
  text-decoration: underline;
  transition: .25s;
}
a:hover {
  color: #666666;
  text-decoration: none;
}

.fw5 {
  font-weight: 500 !important;
}

.fw7 {
  font-weight: 700 !important;
}

header {
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 10;
}
header .inner {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
@media screen and (min-width: 1140px), print {
  header .inner {
    height: 86px;
    padding: 0 3.1vw;
  }
}
@media screen and (max-width: 1140px), print {
  header .inner {
    width: 100%;
    height: 59px;
  }
  header .inner:after {
    content: "";
    width: 100%;
    height: 59px;
    background: #fff;
    position: absolute;
    z-index: 1;
  }
}
header h1 {
  display: block;
  position: absolute;
  z-index: 2;
}
@media screen and (min-width: 1140px), print {
  header h1 {
    width: 240px;
    top: 14px;
  }
}
@media screen and (max-width: 1140px), print {
  header h1 {
    width: 185px;
    top: 50%;
    left: 17px;
    transform: translate(0, -50%);
  }
}
header h1 a {
  display: block;
}
@media screen and (max-width: 1140px), print {
  header h1 a {
    width: 100%;
  }
}
@media screen and (min-width: 1140px), print {
  header h1 a img.en {
    width: 207px;
    height: 59px;
  }
}
@media screen and (max-width: 1140px), print {
  header h1 a img.en {
    width: 138px;
    height: 40px;
  }
}

nav {
  position: absolute;
  top: 15px;
  background: #fff;
  z-index: 0;
}
@media screen and (min-width: 1140px), print {
  nav {
    right: 40px;
    top: 40px;
    display: flex;
  }
}
@media screen and (max-width: 1140px), print {
  nav {
    top: 0;
    left: 0;
    padding-bottom: 24px;
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: .25s;
  }
  nav.on {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100vh;
    padding-top: 59px;
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 1140px), print {
  nav .global_link {
    width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 1140px), print {
  nav .global_link {
    width: 100%;
    border-top: 4px solid #f3f6f7;
  }
}
@media screen and (min-width: 1140px), print {
  nav .global_link ul {
    overflow: hidden;
  }
}
nav .global_link li {
  display: inline-block;
}
@media screen and (min-width: 1140px), print {
  nav .global_link li {
    margin: 0 5px;
  }
}
@media screen and (max-width: 1140px), print {
  nav .global_link li {
    margin: 0;
    display: block;
    border-bottom: 1px solid #f3f6f7;
  }
}
@media screen and (max-width: 1140px), print {
  nav .global_link li.en {
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 1140px), print {
  nav .global_link li.en a, nav .global_link li.en p {
    text-align: center;
  }
}
nav .global_link li.en p {
  color: #aaa;
}
@media screen and (min-width: 1140px), print {
  nav .global_link li.en p {
    display: inline;
  }
}
nav .global_link li a, nav .global_link li p {
  color: #000;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1140px), print {
  nav .global_link li a, nav .global_link li p {
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 8px;
    position: relative;
  }
}
@media screen and (max-width: 1140px), print {
  nav .global_link li a, nav .global_link li p {
    font-size: 14px;
    display: block;
    width: 100%;
    padding: 15px 16px;
    line-height: 1;
  }
  nav .global_link li a:after, nav .global_link li p:after {
    content: attr(data-text);
    color: #999999;
    font-size: 10px;
    margin-left: 8px;
    font-weight: normal;
    font-family: "Roboto", sans-serif;
  }
}
@media screen and (min-width: 1140px), print {
  nav .global_link li .nav_denken img {
    display: none;
  }
}
@media screen and (max-width: 1140px), print {
  nav .global_link li .nav_denken img {
    width: 160px;
    display: inline-block;
  }
}
@media screen and (max-width: 1140px), print {
  nav .global_link li .nav_denken span {
    display: none;
  }
}
@media screen and (min-width: 1140px), print {
  nav .global_link li > a:after {
    content: "";
    height: 0px;
    background: #d8d8d8;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transition: .25s;
  }
  nav .global_link li > a:hover:after {
    height: 4px;
  }
}
nav .global_link li .togglebtn {
  position: relative;
  cursor: pointer;
}
@media screen and (max-width: 1140px), print {
  nav .global_link li .togglebtn:before {
    content: "";
    width: 9px;
    height: 9px;
    border-top: 2px solid #999;
    border-right: 2px solid #999;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: rotate(135deg);
    margin-top: -6px;
    transition: .25s;
  }
}
nav .global_link li .togglebtn.on:before {
  transform: rotate(-45deg);
  margin-top: -1px;
}
@media screen and (min-width: 1140px), print {
  nav .global_link li .togglebtn {
    padding-bottom: 13px;
  }
  nav .global_link li .togglebtn:hover + .togglebox {
    height: 220px;
  }
}
@media screen and (min-width: 1140px), print {
  nav .global_link li .togglebtn.hotel:hover + .togglebox {
    height: auto;
  }
}
nav .global_link li .togglebox {
  display: none;
}
@media screen and (min-width: 1140px), print {
  nav .global_link li .togglebox {
    display: block;
    overflow: hidden;
    position: absolute;
    background: #fff;
    width: 180px;
    height: 0;
    margin-left: -53px;
    box-shadow: 0px 0px 8px 0px rgba(134, 134, 134, 0.22);
    transition: .25s;
    transition-duration: 0.2s;
  }
  nav .global_link li .togglebox:hover {
    height: 220px;
  }
}
@media screen and (min-width: 1140px), print {
  nav .global_link li .togglebox.hotel:hover {
    height: auto;
  }
}
nav .global_link li .togglebox a {
  padding-left: 41px;
  position: relative;
  border-bottom: 1px solid #f3f6f7;
}
@media screen and (min-width: 1140px), print {
  nav .global_link li .togglebox a {
    padding: 1em 15px;
    display: block;
    text-align: left;
  }
}
@media screen and (min-width: 1140px), print {
  nav .global_link li .togglebox a:hover:before {
    height: 4px;
  }
}
@media screen and (min-width: 1140px), print {
  nav .global_link li .togglebox a:before {
    content: "";
    height: 0px;
    background: #d8d8d8;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transition: .25s;
  }
}
@media screen and (max-width: 1140px), print {
  nav .global_link li .togglebox a:before {
    content: "";
    width: 16px;
    height: 1px;
    background: #656565;
    left: 17px;
    top: 50%;
    position: absolute;
    margin-left: 0;
  }
}
@media screen and (min-width: 1140px), print {
  nav .global_link li .togglebox a:after {
    content: attr(data-text);
    color: #999999;
    font-size: 10px;
    margin-left: 8px;
    font-weight: normal;
    font-family: "Roboto", sans-serif;
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -0.7em;
  }
}
nav .global_link li .togglebox a:first-child {
  border-top: 1px solid #f3f6f7;
}
nav .global_link li .togglebox a:last-child {
  border-bottom: none;
}
nav .global_link li .togglebox .icn_pdf {
  position: relative;
  display: inline-block;
  margin: 0 3px;
}
@media screen and (min-width: 1140px), print {
  nav .global_link li .togglebox .icn_pdf {
    width: 15px;
    top: -4px;
  }
}
@media screen and (max-width: 1140px), print {
  nav .global_link li .togglebox .icn_pdf {
    width: 22px;
    top: 1px;
    margin-top: -7px;
  }
}
nav .other_link {
  margin-bottom: 28px;
}
@media screen and (min-width: 1140px), print {
  nav .other_link {
    display: none;
  }
}
nav .other_link li {
  background: #656565;
  display: block;
  border-bottom: 1px solid #fff;
}
nav .other_link a {
  color: #fff;
  font-size: 12px;
  text-decoration: none;
  padding: 1em 16px;
  display: block;
}
nav .other_link a:after {
  content: attr(data-text);
  color: #bbb;
  font-size: 10px;
  margin-left: 8px;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
}
nav .copy {
  font-size: 9px;
  text-align: center;
}
@media screen and (min-width: 1140px), print {
  nav .copy {
    display: none;
  }
}

@media screen and (min-width: 1140px), print {
  .sns {
    width: 52px;
    margin-left: 10px;
    margin-top: 1px;
  }
}
@media screen and (max-width: 1140px), print {
  .sns {
    margin-bottom: 28px;
    text-align: center;
  }
}
.sns li {
  display: inline-block;
  margin: 0 9px;
  text-align: center;
}
@media screen and (min-width: 1140px), print {
  .sns li {
    margin: 0;
  }
  .sns li:first-child {
    margin-right: 10px;
  }
}
.sns a {
  display: block;
  width: 16px;
  height: 16px;
}
.sns a.fb img {
  margin: 0 auto;
  width: 9px;
}

.btn-menu {
  z-index: 20000;
  position: absolute;
  right: 0;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: none;
}
.btn-menu > span {
  position: absolute;
  left: 0;
  right: 0;
  width: 24px;
  height: 1px;
  margin: 0 auto;
  background: #000;
  transition: .25s;
}
.btn-menu > span:nth-child(1) {
  top: 25px;
}
.btn-menu > span:nth-child(2) {
  top: 30px;
}
.btn-menu > span:nth-child(3) {
  top: 35px;
}
.btn-menu.on span:nth-child(1) {
  top: 30px;
  transform: rotate(45deg);
}
.btn-menu.on span:nth-child(2) {
  opacity: 0;
  transform: rotate(90deg);
}
.btn-menu.on span:nth-child(3) {
  top: 30px;
  transform: rotate(135deg);
}
@media screen and (max-width: 1140px), print {
  .btn-menu {
    display: block;
    width: 68px;
    height: 59px;
  }
  .btn-menu > span {
    width: 20px;
    height: 2px;
  }
  .btn-menu > span:nth-child(1) {
    top: 23px;
  }
  .btn-menu > span:nth-child(2) {
    top: 29px;
  }
  .btn-menu > span:nth-child(3) {
    top: 35px;
  }
  .btn-menu.on span:nth-child(1), .btn-menu.on span:nth-child(3) {
    top: 29px;
  }
}

.gototop {
  margin: 0 auto;
}

footer {
  position: relative;
}
@media screen and (min-width: 769px), print {
  footer {
    margin-top: 115px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 768px), print {
  footer {
    margin-top: 64px;
    padding-bottom: 48px;
  }
}
footer:before {
  content: "";
  position: absolute;
  height: 1px;
  background: #dddddd;
  top: 0;
}
@media screen and (min-width: 769px), print {
  footer:before {
    left: 0;
    right: 0;
  }
}
@media screen and (max-width: 768px), print {
  footer:before {
    left: 5vw;
    right: 5vw;
  }
}
footer .inner {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
@media screen and (min-width: 769px), print {
  footer .inner {
    margin: 0 auto;
    max-width: 1080px;
    padding: 0 20px;
  }
}
@media screen and (max-width: 768px), print {
  footer .inner {
    width: 100%;
  }
}

@media screen and (min-width: 769px), print {
  .footer_logo {
    display: flex;
    margin: 0 auto 37px;
  }
}
@media screen and (max-width: 768px), print {
  .footer_logo {
    display: block;
    margin: 0 auto;
    padding-top: 32px;
  }
}
.footer_logo a {
  display: block;
}
@media screen and (min-width: 769px), print {
  .footer_logo a {
    width: 30.673%;
    padding-right: 30px;
  }
}
@media screen and (max-width: 768px), print {
  .footer_logo a {
    width: 240px;
    margin: 0 auto 1.5em;
  }
}
@media screen and (min-width: 769px), print {
  .footer_logo a img.en {
    width: 284px;
    height: 81px;
  }
}
@media screen and (max-width: 768px), print {
  .footer_logo a img.en {
    width: 236px;
    height: 68px;
  }
}
.footer_logo p {
  line-height: 1.8;
}
@media screen and (min-width: 769px), print {
  .footer_logo p {
    font-size: 13px;
    width: 69.327%;
  }
}
@media screen and (max-width: 768px), print {
  .footer_logo p {
    font-size: 12px;
    padding: 0 40px;
  }
}
@media screen and (min-width: 769px), print {
  .footer_links ul {
    text-align: left;
  }
}
@media screen and (max-width: 768px), print {
  .footer_links ul {
    width: 100%;
    padding: 23px 0;
    text-align: center;
  }
}
.footer_links li {
  display: inline-block;
}
@media screen and (min-width: 769px), print {
  .footer_links li {
    font-size: 13px;
    margin-right: 30px;
  }
}
@media screen and (max-width: 768px), print {
  .footer_links li {
    margin: 0 5px;
  }
}
.footer_links a {
  font-size: 12px;
  text-decoration: none;
  border-bottom: 2px solid #d8d8d8;
  padding-bottom: 3px;
  transition-duration: 0s;
}
.footer_links a:hover {
  border-bottom: none;
}
@media screen and (min-width: 769px), print {
  .footer_copy {
    position: absolute;
    text-align: right;
    font-size: 13px;
    margin-top: 30px;
    right: 20px;
    bottom: 0;
    font-size: 10px;
  }
  .footer_copy br {
    display: none;
  }
}
@media screen and (max-width: 768px), print {
  .footer_copy {
    text-align: center;
    font-size: 9px;
  }
}

.tt_capitalize {
  text-transform: capitalize;
}

.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget iframe[style] {
  width: 100% !important;
}

@media screen and (max-width: 768px), print {
  .box {
    width: 100%;
  }
}

.spot_btn_wrap {
  display: flex;
  justify-content: center;
}
.spot_btn_wrap .btn.back {
  margin: 0;
}
@media screen and (max-width: 768px), print {
  .spot_btn_wrap .btn.reservation {
    margin: 0 0 60px 0;
  }
}
@media screen and (min-width: 769px), print {
  .spot_btn_wrap .btn.reservation {
    margin: 0 0 50px 0;
  }
}

.btn {
  display: block;
  border-radius: 3px;
  border: 1px solid #1b1b1b;
  background: #fff;
  color: #333333;
  font-weight: bold;
  text-align: center;
  position: relative;
  text-decoration: none;
  box-shadow: 0px 2px 0px 0px #cecece;
  letter-spacing: 0.05em;
  transition: none;
}
@media screen and (min-width: 769px), print {
  .btn {
    width: 500px;
    height: 52px;
  }
  .btn:hover {
    top: 2px;
    box-shadow: 0px 0px 0px 0px #cecece;
  }
}
@media screen and (max-width: 768px), print {
  .btn {
    width: 240px;
    height: 48px;
  }
}
.btn span {
  position: absolute;
  display: block;
  width: 100%;
  font-size: 14px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.btn.back {
  border: 1px solid #434343;
  background: #434343;
  color: #fff;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  font-weight: normal;
}
.btn.back:after {
  content: "";
  width: 6px;
  height: 6px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  position: absolute;
  left: 16px;
  top: 50%;
  margin-top: -3px;
  transform: rotate(-135deg);
}
@media screen and (min-width: 769px), print {
  .btn.back:after {
    right: 3px;
  }
}
.btn.back {
  background: #434343;
  position: relative;
  margin: 0 auto;
}
.btn.back:after {
  content: "";
  width: 5px;
  height: 5px;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
  position: absolute;
  left: 18px;
  top: 50%;
  margin-top: -3px;
  transform: rotate(-135deg);
}
.btn.back span {
  font-size: 14px;
  color: #fff;
  font-weight: normal;
}
.btn.reservation {
  border: 1px solid #bd700d;
  background: #bd700d;
  color: #fff;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  font-weight: normal;
}
.btn.reservation {
  background: #bd700d;
  position: relative;
  margin: 0 auto;
}
.btn.reservation span {
  font-size: 14px;
  color: #fff;
  font-weight: normal;
}

.submit {
  display: block;
  border-radius: 3px;
  background: #cb1b45;
  color: #fff;
  font-weight: bold;
  text-align: center;
  position: relative;
  text-decoration: none;
  box-shadow: 0px 2px 0px 0px #cecece;
}
@media screen and (min-width: 769px), print {
  .submit {
    width: 500px;
    height: 52px;
    transition-duration: 0s;
  }
  .submit:hover {
    color: #fff;
    transform: translate(0, 2px);
    box-shadow: 0px 0px 0px 0px #cecece;
  }
}
@media screen and (max-width: 768px), print {
  .submit {
    width: 240px;
    height: 48px;
  }
  .submit:hover {
    color: #fff;
  }
}

@media screen and (max-width: 768px), print {
  .submit.en {
    width: 288px;
  }
}

.spot_list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.spot_list li {
  display: block;
  border: 2px solid #ffb3b3;
  border-radius: 3px;
  overflow: hidden;
}
@media screen and (min-width: 769px), print {
  .spot_list li {
    width: 23.867%;
    margin-bottom: 1.2vw;
    margin-left: 1.51%;
  }
  .spot_list li:nth-child(4n+1) {
    margin-left: 0;
  }
}
@media screen and (max-width: 768px), print {
  .spot_list li {
    width: 48.612%;
    margin-bottom: 5vw;
  }
  .spot_list li:nth-child(2n) {
    margin-left: 2.75%;
  }
}
.spot_list li a {
  text-decoration: none;
  display: block;
}
@media screen and (min-width: 769px), print {
  .spot_list li a:hover .spot_img {
    transform: scale(1.03);
  }
}
.spot_list li a img {
  width: 100%;
  display: block;
}
.spot_list li h2 {
  text-align: left;
  color: #ff7f7f;
  margin-bottom: 1em;
}
.spot_list li h2:after {
  display: none;
}
@media screen and (min-width: 769px), print {
  .spot_list li h2 {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px), print {
  .spot_list li h2 {
    font-size: 14px;
  }
}
.spot_list li p {
  text-align: left;
  font-size: 12px;
}
.spot_img_wrap {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.spot_img_wrap span {
  position: absolute;
  color: #fff;
  font-weight: bold;
  right: 0;
  bottom: 0;
  background: #ff7f7f;
}
@media screen and (min-width: 769px), print {
  .spot_img_wrap span {
    padding: 8px 15px;
    font-size: 12px;
    letter-spacing: 0.15em;
  }
}
@media screen and (max-width: 768px), print {
  .spot_img_wrap span {
    padding: 3px 9px;
    font-size: 11px;
    letter-spacing: 0.05em;
  }
}
.spot_img {
  position: relative;
  transition: transform 0.4s ease-out;
  background: url(../img/common/noimage.png) no-repeat center;
  background-size: cover;
}
@media screen and (min-width: 769px), print {
  .spot_info {
    padding: 22px;
  }
}
@media screen and (max-width: 768px), print {
  .spot_info {
    padding: 20px 10px;
  }
}

@media screen and (min-width: 769px), print {
  .event_list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
}
.event_list li {
  display: block;
  border: 1px solid #bfbfbf;
  border-radius: 3px;
  overflow: hidden;
}
@media screen and (min-width: 769px), print {
  .event_list li {
    width: 49.2%;
    margin-bottom: 1.2vw;
  }
  .event_list li:nth-child(2n) {
    margin-left: 1.6%;
  }
}
@media screen and (max-width: 768px), print {
  .event_list li {
    margin-bottom: 16px;
  }
}
.event_list li a {
  position: relative;
  display: block;
  text-decoration: none;
  width: 100%;
}
@media screen and (min-width: 769px), print {
  .event_list li a:hover .event_img {
    transform: scale(1.03);
  }
}
.event_list li a img {
  width: 100%;
  display: block;
}
.event_list li a small {
  text-align: center;
  display: block;
  font-weight: bold;
  margin-bottom: 25px;
  position: relative;
}
@media screen and (min-width: 769px), print {
  .event_list li a small {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px), print {
  .event_list li a small {
    font-size: 10px;
  }
}
.event_list li a small:after {
  content: "";
  background: #bfbfbf;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
}
@media screen and (min-width: 769px), print {
  .event_list li a small:after {
    width: 12px;
    height: 1px;
    bottom: -9px;
  }
}
@media screen and (max-width: 768px), print {
  .event_list li a small:after {
    width: 16px;
    height: 2px;
    bottom: -12px;
  }
}
.event_list li a h2 {
  text-align: left;
  display: block;
  margin: 0;
}
@media screen and (min-width: 769px), print {
  .event_list li a h2 {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px), print {
  .event_list li a h2 {
    font-size: 14px;
  }
}
.event_list li a h2:after {
  display: none;
}
@media screen and (min-width: 769px), print {
  .event_list li a p {
    font-size: 12px;
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px), print {
  .event_list li a p {
    display: none;
  }
}
.event_img_wrap {
  overflow: hidden;
  width: 100%;
}
.event_img {
  width: 100%;
  transition: transform 0.4s ease-out;
  background: url(../img/common/noimage.png) no-repeat;
  background-size: cover;
}
.event_info {
  color: #333333;
  position: relative;
}
@media screen and (min-width: 769px), print {
  .event_info {
    padding: 22px 11% 30px;
  }
}
@media screen and (max-width: 768px), print {
  .event_info {
    padding: 22px 15px 25px;
  }
}
.event_info:after {
  content: "";
  border-radius: 3px;
  display: inline-block;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%, 0);
  color: #fff;
  font-weight: bold;
  white-space: nowrap;
  line-height: 1;
  font-size: 12px;
  padding: 10px 18px;
}
.event_info.coming:after {
  content: "開催予定";
  background: #f39800;
  border-radius: 3px;
}
.event_info.end:after {
  content: "終了したイベント";
  background: #7d7d7d;
  border-radius: 3px;
}

.topics_list li {
  border-bottom: 1px solid #eee;
}
@media screen and (min-width: 769px), print {
  .topics_list li {
    display: flex;
    margin-bottom: 22px;
    padding-bottom: 22px;
  }
}
@media screen and (max-width: 768px), print {
  .topics_list li {
    margin-bottom: 24px;
    padding-bottom: 24px;
  }
}
.topics_list p {
  text-align: left;
  overflow: hidden;
  position: relative;
}
@media screen and (min-width: 769px), print {
  .topics_list p {
    font-size: 14px;
    display: block;
    width: 270px;
    height: 30px;
    line-height: 30px !important;
  }
}
@media screen and (max-width: 768px), print {
  .topics_list p {
    font-size: 12px;
  }
}
.topics_list p span {
  border-radius: 3px;
  line-height: 1;
  text-align: center;
}
@media screen and (min-width: 769px), print {
  .topics_list p span {
    margin-left: 30px;
    padding: 9px 18px;
    font-size: 11px;
    width: 96px;
    position: absolute;
    top: 0;
    right: 0;
  }
}
@media screen and (max-width: 768px), print {
  .topics_list p span {
    display: inline-block;
    margin-left: 1em;
    padding: 4px 18px;
    position: absolute;
    left: 100px;
  }
}
.topics_list a {
  position: relative;
}
@media screen and (min-width: 769px), print {
  .topics_list a {
    padding-top: 4px;
    font-size: 14px;
    display: block;
    vertical-align: top;
    margin-left: 30px;
    width: 460px;
    padding-right: 26px;
  }
}
@media screen and (max-width: 768px), print {
  .topics_list a {
    padding-top: 7px;
    display: block;
    font-size: 13px;
    padding-right: 10px;
  }
}
.topics_list a:after {
  content: "";
  width: 7px;
  height: 7px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  position: absolute;
  right: 0;
  top: 50%;
  transform: rotate(45deg);
}
@media screen and (min-width: 769px), print {
  .topics_list a:after {
    right: 3px;
  }
}

.tag_news {
  background: #eee;
}
.tag_recruitment {
  background: #e0efff;
}
.tag_event {
  background: #ffefe0;
}

@media screen and (min-width: 769px), print {
  .post_mainimage {
    margin-bottom: 50px;
  }
  .post_mainimage.noimg {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px), print {
  .post_mainimage {
    margin-bottom: 30px;
  }
}
.post_mainimage img {
  margin-bottom: 0.5em;
  width: 100%;
}
.post_mainimage p {
  text-align: center;
}
@media screen and (min-width: 769px), print {
  .post_mainimage p {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px), print {
  .post_mainimage p {
    font-size: 10px;
  }
}
.post_mainimage a {
  color: #002a5f;
  text-decoration: underline;
}
@media screen and (min-width: 769px), print {
  .post_mainimage a:hover {
    text-decoration: none;
  }
}
.post_contents {
  border-bottom: 1px solid #eee;
}
@media screen and (min-width: 769px), print {
  .post_contents {
    padding: 0 0 30px;
    margin: 0 6.2vw 25px;
  }
}
@media screen and (max-width: 768px), print {
  .post_contents {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 769px), print {
  .post_contents p {
    margin-bottom: 2em;
  }
}
@media screen and (max-width: 768px), print {
  .post_contents p {
    margin-bottom: 2em;
  }
}
.post_contents a {
  color: #002a5f;
  text-decoration: underline;
}
@media screen and (min-width: 769px), print {
  .post_contents a:hover {
    text-decoration: none;
  }
}

@media screen and (min-width: 769px), print {
  .pagenation {
    padding-top: 0px;
  }
}
@media screen and (max-width: 768px), print {
  .pagenation {
    padding-top: 10px;
  }
}
@media screen and (min-width: 769px), print {
  .pagenation.single {
    margin: 0 95px;
  }
  .pagenation.single ul {
    padding-top: 55px;
  }
}
@media screen and (max-width: 768px), print {
  .pagenation.single {
    margin: 0 5vw;
  }
}
.pagenation ul {
  text-align: center;
  position: relative;
}
@media screen and (min-width: 769px), print {
  .pagenation ul {
    padding-top: 46px;
  }
}
@media screen and (max-width: 768px), print {
  .pagenation ul {
    padding-top: 53px;
  }
}
.pagenation li {
  display: inline-block;
}
@media screen and (min-width: 769px), print {
  .pagenation li {
    margin: 0 3px;
  }
}
@media screen and (max-width: 768px), print {
  .pagenation li {
    margin: 0 2px;
  }
}
.pagenation li.pagerPrev {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
}
.pagenation li.pagerPrev a {
  width: auto;
  height: auto;
  line-height: 1.6;
  border: none;
  text-decoration: underline;
  position: relative;
}
@media screen and (min-width: 769px), print {
  .pagenation li.pagerPrev a {
    font-size: 14px;
  }
  .pagenation li.pagerPrev a:hover {
    text-decoration: none;
    color: #666666;
    background: none;
  }
}
.pagenation li.pagerPrev a:after {
  content: "";
  width: 7px;
  height: 7px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  position: absolute;
  left: -12px;
  margin-top: -3px;
  top: 50%;
  transform: rotate(-135deg);
}
.pagenation li.pagerNext {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
}
.pagenation li.pagerNext a {
  width: auto;
  height: auto;
  line-height: 1.6;
  border: none;
  text-decoration: underline;
  position: relative;
}
@media screen and (min-width: 769px), print {
  .pagenation li.pagerNext a {
    font-size: 14px;
  }
  .pagenation li.pagerNext a:hover {
    text-decoration: none;
    color: #666666;
    background: none;
  }
}
.pagenation li.pagerNext a:after {
  content: "";
  width: 7px;
  height: 7px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  position: absolute;
  right: -12px;
  top: 50%;
  margin-top: -3px;
  transform: rotate(45deg);
}
.pagenation a, .pagenation span {
  display: block;
  border-radius: 3px;
  border: 1px solid #434343;
  text-align: center;
  text-decoration: none;
}
@media screen and (min-width: 769px), print {
  .pagenation a, .pagenation span {
    font-size: 16px;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .pagenation a:hover, .pagenation span:hover {
    background: #434343;
    color: #fff;
  }
}
@media screen and (max-width: 768px), print {
  .pagenation a, .pagenation span {
    width: 32px;
    height: 32px;
    line-height: 32px;
  }
}
.pagenation span {
  background: #434343;
  color: #fff;
}

.share {
  position: relative;
}
.share ul {
  line-height: 0;
  width: 100%;
  text-align: center;
}
.share li {
  width: 32px;
  height: 32px;
  border-radius: 3px;
  display: inline-block;
  overflow: hidden;
  margin: 0 2px;
}
.share li a {
  display: block;
  width: 32px;
  height: 32px;
  background: #55acee;
  text-align: center;
  padding-top: 6px;
}
.share li.fb a {
  background: #315096;
}
@media screen and (min-width: 769px), print {
  .share li.li {
    display: none;
  }
}
.share li.li a {
  background: #00c300;
}
.share li svg {
  width: 20px;
  height: 20px;
}
.share li path {
  fill: #fff;
}

.post_share {
  position: relative;
  display: flex;
}
@media screen and (min-width: 769px), print {
  .post_share {
    float: right;
    width: 30%;
    margin-left: 70%;
  }
}
.post_share a {
  display: inline-block;
  overflow: hidden;
  margin: 0;
  background: #55acee;
  text-align: center;
  padding-top: 6px;
}
@media screen and (min-width: 769px), print {
  .post_share a {
    width: 32px;
    height: 32px;
  }
}
@media screen and (max-width: 768px), print {
  .post_share a {
    width: 33.33%;
    height: 36px;
  }
}
.post_share a.fb {
  background: #315096;
}
.post_share a.li {
  background: #00c300;
}
@media screen and (min-width: 769px), print {
  .post_share a.li {
    display: none;
  }
}
.post_share a svg {
  width: 20px;
  height: 20px;
}
.post_share a path {
  fill: #fff;
}

#spot_map {
  width: 700px;
  height: 400px;
}

.text {
  margin-bottom: 25px;
}
.text--large {
  font-size: 18px;
}
.text--small {
  font-size: 12px;
}
@media screen and (max-width: 768px), print {
  .text--large {
    font-size: 16px;
  }
  .text--small {
    font-size: 10px;
  }
}

.line {
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100%;
  border: none;
  border-top: 1px solid #000;
}
@media screen and (max-width: 768px), print {
  .line {
    margin-top: 17px;
    margin-bottom: 17px;
  }
}

.table {
  margin-top: 35px;
}
.table table {
  width: 100%;
}
.table th,
.table td {
  padding: 20px;
  vertical-align: middle;
}
.table th > *,
.table td > * {
  margin-top: 15px;
}
.table th > *:first-child,
.table td > *:first-child {
  margin-top: 0;
}
.table th {
  color: #fff;
  text-align: left;
}
.table__cell--5 {
  width: 5%;
}
.table__cell--10 {
  width: 10%;
}
.table__cell--15 {
  width: 15%;
}
.table__cell--20 {
  width: 20%;
}
.table__cell--25 {
  width: 25%;
}
.table__cell--30 {
  width: 30%;
}
.table__cell--35 {
  width: 35%;
}
.table__cell--40 {
  width: 40%;
}
.table__cell--45 {
  width: 45%;
}
.table__cell--50 {
  width: 50%;
}
.table__cell--55 {
  width: 55%;
}
.table__cell--60 {
  width: 60%;
}
.table__cell--65 {
  width: 65%;
}
.table__cell--70 {
  width: 70%;
}
.table__cell--75 {
  width: 75%;
}
.table__cell--80 {
  width: 80%;
}
.table__cell--85 {
  width: 85%;
}
.table__cell--90 {
  width: 90%;
}
.table__cell--95 {
  width: 95%;
}
.table__cell--100 {
  width: 100%;
}
@media screen and (max-width: 768px), print {
  .table {
    margin-top: 25px;
  }
  .table th,
  .table td {
    display: block;
    padding: 10px;
  }
  .table__cell {
    width: auto;
  }
}

@media screen and (max-width: 768px), print {
  .caution {
    margin-top: 44px;
  }
}
@media screen and (min-width: 769px), print {
  .caution {
    max-width: 720px;
    margin: 70px auto 0;
  }
}

@media screen and (min-width: 769px), print {
  #top main {
    min-width: 1040px;
    width: 100%;
  }
}
#top h2 {
  text-align: center;
  display: block;
  padding-top: 51px;
  position: relative;
  line-height: 1;
  margin-bottom: 41px;
}
@media screen and (min-width: 769px), print {
  #top h2 {
    font-size: 24px;
  }
}
@media screen and (max-width: 768px), print {
  #top h2 {
    font-size: 18px;
  }
}
#top h2:after {
  content: "";
  width: 26px;
  height: 33px;
  background: url(../img/common/icn_topics.png) no-repeat top center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
#top h2 small {
  margin-top: 10px;
  display: block;
  font-family: "Roboto", sans-serif;
  color: #999999;
  font-weight: bold;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 769px), print {
  #top h2 small {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px), print {
  #top h2 small {
    font-size: 10px;
  }
}
@media screen and (min-width: 769px), print {
  #top .top_firstview {
    margin-bottom: 116px;
  }
}
@media screen and (max-width: 768px), print {
  #top .top_firstview {
    margin-bottom: 46px;
  }
}
#top .top_slide {
  background: #000;
}
@media screen and (min-width: 769px), print {
  #top .top_slide {
    width: 100%;
  }
}
@media screen and (max-width: 768px), print {
  #top .top_slide {
    width: 100%;
  }
}
#top .top_slide li.slick-slide {
  position: relative;
}
@media screen and (min-width: 769px), print {
  #top .top_slide li.slick-slide {
    width: 100%;
    height: 100vh;
  }
}
@media screen and (max-width: 768px), print {
  #top .top_slide li.slick-slide {
    width: 100%;
    height: 100vh;
  }
}
@media screen and (min-width: 769px), print {
  #top .top_slide li.slick-slide:nth-child(2) div {
    background-position: right !important;
  }
}
#top .top_slide li.slick-slide a {
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
}
@media screen and (min-width: 769px), print {
  #top .top_slide li.slick-slide a {
    top: 86px;
  }
}
@media screen and (max-width: 768px), print {
  #top .top_slide li.slick-slide a {
    top: 59px;
  }
}
#top .top_slide li.slick-slide a:after {
  content: "";
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  position: absolute;
}
#top .top_slide li.slick-slide div {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}
#top .top_slide li.slick-slide iframe {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.5s linear;
}
@media screen and (min-width: 769px), print {
  #top .top_slide li.slick-slide iframe {
    width: 100%;
    height: 60vw;
  }
}
@media screen and (max-width: 768px), print {
  #top .top_slide li.slick-slide iframe {
    width: 200vh;
    height: 100vh;
  }
}
#top .top_slide li.slick-slide iframe.on {
  opacity: 1;
}
#top .toptopics {
  margin: 0 15px 72px;
}
@media screen and (min-width: 769px), print {
  #top .toptopics {
    width: 1040px;
    margin: 0 auto;
    position: relative;
    display: flex;
  }
}
@media screen and (min-width: 769px), print {
  #top .toptopics h2 {
    padding: 0;
    padding-left: 56px;
    padding-top: 32px;
    text-align: left;
    width: 397px;
    letter-spacing: 0.05em;
  }
}
@media screen and (min-width: 769px), print {
  #top .toptopics h2:before {
    content: "";
    background: #333;
    width: 40px;
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
  }
}
#top .toptopics h2:after {
  background: url(../img/common/icn_topics.png) no-repeat top center;
  background-size: cover;
}
@media screen and (min-width: 769px), print {
  #top .toptopics h2:after {
    width: 40px;
    height: 50px;
    left: 0;
    top: 28px;
    right: auto;
    margin: 0;
  }
}
@media screen and (max-width: 768px), print {
  #top .toptopics h2:after {
    width: 26px;
    height: 33px;
  }
}
@media screen and (min-width: 769px), print {
  #top .toptopics .topics_list {
    width: 643px;
    height: 225px;
    overflow: hidden;
  }
  #top .toptopics .topics_list p, #top .toptopics .topics_list span, #top .toptopics .topics_list a {
    font-size: 12px;
  }
}
#top .toptopics .topics_list li:nth-child(3) {
  border-bottom: none;
}
#top .toptopics .btn {
  width: 144px;
}
@media screen and (min-width: 769px), print {
  #top .toptopics .btn {
    position: absolute;
    left: 0;
    top: 114px;
    height: 40px;
    line-height: 40px;
  }
  #top .toptopics .btn span {
    font-size: 13px;
  }
}
@media screen and (max-width: 768px), print {
  #top .toptopics .btn {
    margin: 37px auto 0;
  }
}
#top .topproject {
  background: url(../img/top/bg_sp.jpg);
  background-size: cover;
}
@media screen and (min-width: 769px), print {
  #top .topproject {
    padding: 40px;
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 768px), print {
  #top .topproject {
    padding: 40px 16px 30px;
    margin-bottom: 34px;
  }
}
@media screen and (min-width: 768px), print {
  #top .topproject {
    padding: 40px 100px 24px;
  }
}
@media screen and (min-width: 769px), print {
  #top .topproject .inner {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }
}
#top .topproject_bnr {
  overflow: hidden;
  box-shadow: 0px 2px 0px 0px #cecece;
}
@media screen and (min-width: 769px), print {
  #top .topproject_bnr {
    margin-bottom: 16px;
    border-radius: 3px;
    overflow: hidden;
  }
  #top .topproject_bnr img {
    display: none;
  }
}
@media screen and (max-width: 768px), print {
  #top .topproject_bnr {
    border-radius: 5px;
    margin-bottom: 10px;
  }
}
#top .topproject_bnr a {
  display: block;
  line-height: 1;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media screen and (min-width: 769px), print {
  #top .topproject_bnr a {
    transition: transform 0.4s ease-out;
  }
  #top .topproject_bnr a:hover {
    transform: scale(1.03);
  }
}
@media screen and (min-width: 769px), print {
  #top .topproject_bnr.large {
    width: 100%;
  }
}
@media screen and (min-width: 769px), print {
  #top .topproject_bnr.large a {
    width: 100%;
    height: 400px;
  }
}
@media screen and (min-width: 769px), print {
  #top .topproject_bnr.medium {
    width: 49.334%;
  }
  #top .topproject_bnr.medium + .medium {
    margin-left: 1.332%;
  }
}
@media screen and (min-width: 769px), print {
  #top .topproject_bnr.medium a {
    width: 100%;
    height: 300px;
  }
}
#top .topspot {
  margin-bottom: 46px;
}
@media screen and (min-width: 769px), print {
  #top .topspot {
    width: 1040px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 769px), print {
  #top .topspot h2 {
    padding-top: 0;
    padding-left: 98px;
    display: inline-block;
  }
  #top .topspot h2 small {
    display: inline-block;
    padding-left: 18px;
    margin-left: 18px;
    border-left: 1px solid #000;
    height: 24px;
    line-height: 24px;
    margin-top: 0;
    vertical-align: top;
  }
}
#top .topspot h2:after {
  background: url(../img/common/icn_spot.png) no-repeat top center;
  background-size: cover;
}
@media screen and (min-width: 769px), print {
  #top .topspot h2:after {
    width: 80px;
    height: 54px;
    left: 0;
    top: -11px;
    right: auto;
    margin: 0;
  }
}
@media screen and (max-width: 768px), print {
  #top .topspot h2:after {
    width: 59px;
    height: 40px;
  }
}
@media screen and (min-width: 769px), print {
  #top .topspot_list {
    overflow: hidden;
    margin-bottom: 64px;
  }
}
@media screen and (max-width: 768px), print {
  #top .topspot_list {
    overflow: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
#top .topspot_list ul {
  display: flex;
  margin: 0 auto;
}
@media screen and (min-width: 769px), print {
  #top .topspot_list ul {
    width: 1040px;
  }
}
@media screen and (max-width: 768px), print {
  #top .topspot_list ul {
    width: 920px;
    padding: 0 12px;
  }
}
#top .topspot_list li {
  border-radius: 10px;
  border: 2px solid #ff7f7f;
  padding-bottom: 23px;
  text-align: center;
  background: url(../img/top/spot_eat.jpg) no-repeat top center;
  background-size: contain;
}
@media screen and (min-width: 769px), print {
  #top .topspot_list li {
    width: 248px;
    margin-right: 16px;
    padding-top: 256px;
  }
  #top .topspot_list li:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 768px), print {
  #top .topspot_list li {
    width: 200px;
    margin: 0 12px;
    padding-top: 185px;
  }
}
#top .topspot_list li.stay {
  background: url(../img/top/spot_stay.jpg) no-repeat top center;
  background-size: contain;
  border-color: #bd700d;
}
#top .topspot_list li.stay h3 {
  color: #bd700d;
}
#top .topspot_list li.stay h3 span:after {
  background: #bd700d;
}
#top .topspot_list li.stay .btn {
  border-color: #bd700d;
}
#top .topspot_list li.stay .btn span {
  color: #bd700d;
}
#top .topspot_list li.see {
  background: url(../img/top/spot_see.jpg) no-repeat top center;
  background-size: contain;
  border-color: #7fbfff;
}
#top .topspot_list li.see h3 {
  color: #7fbfff;
}
#top .topspot_list li.see h3 span:after {
  background: #7fbfff;
}
#top .topspot_list li.see .btn {
  border-color: #7fbfff;
}
#top .topspot_list li.see .btn span {
  color: #7fbfff;
}
#top .topspot_list li.experience {
  background: url(../img/top/spot_experience.jpg) no-repeat top center;
  background-size: contain;
  border-color: #70c120;
}
#top .topspot_list li.experience h3 {
  color: #70c120;
}
#top .topspot_list li.experience h3 span:after {
  background: #70c120;
}
#top .topspot_list li.experience .btn {
  border-color: #70c120;
}
#top .topspot_list li.experience .btn span {
  color: #70c120;
}
#top .topspot_list h3 {
  font-weight: bold;
  color: #ff7f7f;
  line-height: 1;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.1em;
  margin-bottom: 18px;
}
@media screen and (min-width: 769px), print {
  #top .topspot_list h3 {
    font-size: 24px;
  }
}
@media screen and (max-width: 768px), print {
  #top .topspot_list h3 {
    font-size: 20px;
  }
}
#top .topspot_list h3 b {
  display: block;
}
#top .topspot_list h3 span {
  display: inline-block;
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  margin-top: 15px;
  position: relative;
  padding-bottom: 10px;
}
@media screen and (min-width: 769px), print {
  #top .topspot_list h3 span {
    font-size: 20px;
  }
}
#top .topspot_list h3 span:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #ff7f7f;
  position: absolute;
  bottom: 0;
  left: 0;
}
#top .topspot_list p {
  font-size: 14px;
  margin-bottom: 25px;
}
#top .topspot_list .btn {
  width: 112px;
  height: 38px;
  line-height: 38px;
  margin: 0 auto;
  border-color: #ff7f7f;
}
#top .topspot_list .btn span {
  font-size: 12px;
  color: #ff7f7f;
}
@media screen and (min-width: 769px), print {
  #top .topaccess {
    width: 1040px;
    margin: 0 auto 80px !important;
    display: flex;
  }
}
@media screen and (max-width: 768px), print {
  #top .topaccess {
    margin: 0 15px 65px;
  }
}
@media screen and (min-width: 768px), print {
  #top .topaccess {
    margin: 0 100px 65px;
  }
}
@media screen and (min-width: 769px), print {
  #top .topaccess .box {
    width: 512px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
  }
}
@media screen and (min-width: 769px), print {
  #top .topaccess .box:last-child {
    height: 90px;
  }
}
#top .topaccess .accessbnr {
  overflow: hidden;
}
@media screen and (min-width: 769px), print {
  #top .topaccess .accessbnr {
    margin-right: 16px;
    border-radius: 3px;
    width: 512px;
  }
  #top .topaccess .accessbnr a {
    width: 524px;
    height: 260px;
    background: url(../img/top/access_pc.jpg) no-repeat center;
    background-size: cover;
  }
  #top .topaccess .accessbnr a.en {
    background: url(../img/top/access_pc_en.jpg) no-repeat center;
    background-size: cover;
  }
  #top .topaccess .accessbnr a.cn {
    background: url(../img/top/access_pc_cn.jpg) no-repeat center;
    background-size: cover;
  }
  #top .topaccess .accessbnr a.tw {
    background: url(../img/top/access_pc_tw.jpg) no-repeat center;
    background-size: cover;
  }
  #top .topaccess .accessbnr img {
    display: none;
  }
}
@media screen and (max-width: 768px), print {
  #top .topaccess .accessbnr {
    margin-bottom: 24px;
    border-radius: 5px;
    box-shadow: 0px 2px 0px 0px #cecece;
  }
}
#top .topaccess .accessbnr a {
  display: block;
}
#top .topaccess #pageplugin {
  margin: 0 auto;
}
@media screen and (min-width: 769px), print {
  #top .topaccess #pageplugin {
    width: 500px;
  }
}
@media screen and (max-width: 768px), print {
  #top .topaccess #pageplugin {
    max-width: 500px;
  }
}
@media screen and (min-width: 769px), print {
  #top .instagram {
    padding-top: 54px;
    background: url(../img/top/bg_insta_pc.jpg) top center repeat-x;
  }
  #top .instagram .inner {
    width: 1040px;
    margin: 0 auto;
    position: relative;
  }
}
@media screen and (max-width: 768px), print {
  #top .instagram {
    padding-top: 45px;
    background: url(../img/top/bg_insta.jpg) no-repeat top center;
    background-size: contain;
  }
}
@media screen and (min-width: 769px), print {
  #top .instagram h2 {
    padding-top: 0;
    padding-left: 64px;
    display: inline-block;
  }
  #top .instagram h2 small {
    display: inline-block;
    padding-left: 18px;
    margin-left: 18px;
    border-left: 1px solid #000;
    height: 24px;
    line-height: 24px;
    margin-top: 0;
    vertical-align: top;
  }
}
@media screen and (max-width: 768px), print {
  #top .instagram h2 {
    padding-top: 42px;
    margin-bottom: 21px;
  }
}
#top .instagram h2:after {
  background: url(../img/common/icn_instagram.png) no-repeat top center;
  background-size: cover;
}
@media screen and (min-width: 769px), print {
  #top .instagram h2:after {
    width: 44px;
    height: 44px;
    left: 0;
    top: -11px;
    right: auto;
    margin: 0;
  }
}
@media screen and (max-width: 768px), print {
  #top .instagram h2:after {
    width: 26px;
    height: 26px;
  }
}
#top .instagram_inner {
  position: relative;
}
@media screen and (min-width: 769px), print {
  #top .instagram_inner {
    margin: 0 auto;
  }
}
@media screen and (max-width: 768px), print {
  #top .instagram_inner {
    margin: 0 15px;
  }
}
#top .instagram .btn {
  width: 144px;
  height: 40px;
  line-height: 40px;
}
@media screen and (min-width: 769px), print {
  #top .instagram .btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}
#top .instagram .btn span {
  font-size: 13px;
}
#top #instafeed {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
@media screen and (max-width: 768px), print {
  #top #instafeed {
    margin-bottom: 32px;
  }
}
#top #instafeed a {
  overflow: hidden;
  border-radius: 3px;
  box-shadow: 0px 2px 0px 0px #cecece;
}
@media screen and (min-width: 769px), print {
  #top #instafeed a {
    width: 201.5px;
    height: 201.5px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
  #top #instafeed a:nth-child(5n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 768px), print {
  #top #instafeed a {
    width: 48.28%;
    height: 43.75vw;
    margin-bottom: 3.44%;
  }
  #top #instafeed a:nth-child(2n) {
    margin-left: 3.44%;
  }
}
#top #instafeed img {
  width: 100%;
}
@media screen and (min-width: 769px), print {
  #top footer {
    margin-top: 82px;
  }
}

@media screen and (min-width: 769px), print {
  #top.en .toptopics h2 {
    padding-top: 40px;
  }
}
@media screen and (min-width: 769px), print {
  #top.en .instagram h2 {
    padding-top: 10px;
  }
}
@media screen and (min-width: 769px), print {
  #top.en .instagram h2:after {
    top: 0;
  }
}
#top.en .topspot_list p {
  min-height: 44px;
}

@media screen and (min-width: 1140px), print {
  main {
    padding-top: 86px;
  }
}
@media screen and (max-width: 1140px), print {
  main {
    padding-top: 59px;
  }
}

@media screen and (min-width: 769px), print {
  #top main {
    padding-top: 0;
  }
}
@media screen and (max-width: 768px), print {
  #top main {
    padding-top: 0;
  }
}

.lower_firstview {
  width: 100%;
  position: relative;
}
@media screen and (min-width: 769px), print {
  .lower_firstview {
    height: 331px;
    background: url(../img/group/main_pc.jpg) no-repeat center;
    background-size: cover;
  }
}
@media screen and (max-width: 768px), print {
  .lower_firstview {
    height: 197px;
    background: url(../img/group/main_sp.jpg) no-repeat center;
    background-size: cover;
  }
}
.lower_firstview h1 {
  position: relative;
  font-weight: bold;
  text-align: center;
  display: block;
}
@media screen and (min-width: 769px), print {
  .lower_firstview h1 {
    font-size: 28px;
    top: 100px;
  }
}
@media screen and (max-width: 768px), print {
  .lower_firstview h1 {
    font-size: 20px;
    top: 50px;
  }
}
.lower_firstview h1:after {
  content: attr(data-text);
  position: absolute;
  bottom: -1.6em;
  letter-spacing: 0.05em;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  color: #999999;
}
@media screen and (min-width: 769px), print {
  .lower_firstview h1:after {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px), print {
  .lower_firstview h1:after {
    font-size: 10px;
  }
}
.lower_firstview .crumb {
  position: absolute;
  font-size: 12px;
  top: 24px;
  display: block;
  margin: 0 auto;
  left: 3.1vw;
  right: 3.1vw;
  text-align: left;
}
.lower_firstview .crumb > * {
  display: inline-block;
  margin-right: 25px;
}
.lower_firstview .crumb a {
  text-decoration: none;
  position: relative;
}
.lower_firstview .crumb a:after {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  border-color: #333333;
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translate(0, -60%) rotate(45deg);
}
@media screen and (max-width: 768px), print {
  .lower_firstview .crumb {
    display: none;
  }
}
.lower_main {
  width: 100%;
}
.lower_main .headline {
  background: #fff;
  display: block;
  border-radius: 5px;
  position: relative;
}
@media screen and (min-width: 769px), print {
  .lower_main .headline {
    margin: -93px 3.1vw 0;
    padding: 60px 60px 40px;
  }
}
@media screen and (min-width: 1400px), print {
  .lower_main .headline {
    margin: -93px auto 0;
    max-width: 1400px;
  }
}
@media screen and (max-width: 768px), print {
  .lower_main .headline {
    margin: -68px 5vw 0;
    padding: 35px 5vw;
  }
}
.lower_main .headline h2 {
  font-weight: bold;
  text-align: center;
  position: relative;
  line-height: 1.6;
  margin-bottom: 10px;
}
@media screen and (min-width: 769px), print {
  .lower_main .headline h2 {
    font-size: 20px;
  }
}
@media screen and (max-width: 768px), print {
  .lower_main .headline h2 {
    font-size: 16px;
  }
}
.lower_main .headline h2:after {
  content: "";
  position: absolute;
  width: 24px;
  height: 3px;
  background: #bfbfbf;
  display: block;
  bottom: -20px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.lower_main .headline small {
  font-size: 12px;
  display: block;
  line-height: 1.8;
}
.lower_main .headline .post_data {
  text-align: center;
}
@media screen and (min-width: 769px), print {
  .lower_main .headline .post_data {
    font-size: 12px;
    margin-bottom: 1.5em;
  }
}
@media screen and (max-width: 768px), print {
  .lower_main .headline .post_data {
    font-size: 10px;
    margin-bottom: 1em;
  }
}
.lower_main .headline .post_tit {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
@media screen and (min-width: 769px), print {
  .lower_main .headline .post_tit {
    font-size: 20px;
  }
}
@media screen and (max-width: 768px), print {
  .lower_main .headline .post_tit {
    font-size: 16px;
  }
}
.lower_main .headline .post_tag {
  font-size: 12px;
  border-bottom: 1px solid #666666;
  color: #666666;
  text-align: center;
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}
@media screen and (min-width: 769px), print {
  .lower_main .headline .post_tag {
    margin-top: 1.5em;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 768px), print {
  .lower_main .headline .post_tag {
    margin-top: 1em;
    margin-bottom: 15px;
  }
}
.lower_contents {
  color: #666666;
  font-size: 14px;
}
.lower_contents.notitle {
  border-radius: 3px;
  background: #fff;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 769px), print {
  .lower_contents.notitle {
    padding-top: 62px;
    margin: -100px 3.1vw 0;
  }
}
@media screen and (min-width: 1400px), print {
  .lower_contents.notitle {
    margin: -100px auto 0;
    max-width: 1400px;
  }
}
@media screen and (max-width: 768px), print {
  .lower_contents.notitle {
    padding-top: 40px;
    margin: -68px 5vw 0;
  }
}
.lower_contents h2 {
  font-weight: bold;
  text-align: center;
  position: relative;
  line-height: 1.6;
  margin-bottom: 40px;
}
@media screen and (min-width: 769px), print {
  .lower_contents h2 {
    font-size: 20px;
  }
}
@media screen and (max-width: 768px), print {
  .lower_contents h2 {
    font-size: 16px;
  }
}
.lower_contents h2:after {
  content: "";
  position: absolute;
  width: 24px;
  height: 3px;
  background: #bfbfbf;
  display: block;
  bottom: -20px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.lower_contents h3 {
  font-size: 16px;
  font-weight: bold;
  padding: 0.7em 0 0.5em;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
}
@media screen and (min-width: 769px), print {
  .lower_contents h3 {
    margin-bottom: 1.5em;
  }
}
@media screen and (max-width: 768px), print {
  .lower_contents h3 {
    margin-bottom: 1em;
  }
}
.lower_contents h4 {
  font-size: 16px;
  font-weight: bold;
  padding-left: 0.7em;
  border-left: 3px solid #d2d2d2;
}
@media screen and (min-width: 769px), print {
  .lower_contents h4 {
    margin-bottom: 1.5em;
  }
}
@media screen and (max-width: 768px), print {
  .lower_contents h4 {
    margin-bottom: 1em;
  }
}
.lower_contents h5 {
  font-size: 16px;
  font-weight: bold;
}
@media screen and (min-width: 769px), print {
  .lower_contents h5 {
    margin-bottom: 1.5em;
  }
}
@media screen and (max-width: 768px), print {
  .lower_contents h5 {
    margin-bottom: 1em;
  }
}
.lower_contents p {
  line-height: 1.8;
}
.lower_contents b {
  font-weight: bold;
}
.lower_contents hr {
  border-top: 1px solid #eeeeee;
  margin: 3em 0;
}
.lower_contents .txtR {
  text-align: right;
}
.lower_contents .txtL {
  text-align: left;
}
.lower_contents .txtC {
  text-align: center;
}

#obiproject main {
  overflow: hidden;
}
@media screen and (min-width: 769px), print {
  #obiproject .lower_firstview {
    background: url(../img/obiproject/main_pc.jpg) no-repeat center;
    background-size: cover;
  }
}
@media screen and (max-width: 768px), print {
  #obiproject .lower_firstview {
    background: url(../img/obiproject/main_sp.jpg) no-repeat center;
    background-size: cover;
  }
}
@media screen and (min-width: 769px), print {
  #obiproject .lower_contents {
    margin: 0 6.2vw;
  }
}
@media screen and (max-width: 768px), print {
  #obiproject .lower_contents {
    margin: 0 5vw;
  }
}
@media screen and (min-width: 769px), print {
  #obiproject .lower_contents > p {
    text-align: center;
    line-height: 2;
  }
}
@media screen and (max-width: 768px), print {
  #obiproject .lower_contents > p {
    margin: 0 5vw;
  }
}
@media screen and (min-width: 769px), print {
  #obiproject .lower_contents h2 {
    margin-bottom: 60px;
  }
}
#obiproject .obiproject {
  position: relative;
}
@media screen and (min-width: 769px), print {
  #obiproject .obiproject_box {
    margin-bottom: 110px;
  }
}
@media screen and (max-width: 768px), print {
  #obiproject .obiproject_box {
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 769px), print {
  #obiproject .obiproject_img01 {
    background: url(../img/obiproject/photo01_pc.jpg) no-repeat top center;
    background-size: cover;
    max-width: 1040px;
    height: 517px;
    margin: 35px auto 115px;
  }
  #obiproject .obiproject_img01 img {
    display: none;
  }
  #obiproject .obiproject_img01 .slide_obipro {
    display: none;
  }
}
@media screen and (max-width: 768px), print {
  #obiproject .obiproject_img01 {
    margin-top: 35px;
    margin-bottom: 75px;
    /* 親要素の幅から解放 */
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
  }
  #obiproject .obiproject_img01 img {
    width: 100%;
  }
  #obiproject .obiproject_img01 .slide_obipro div {
    border-right: 1px solid #fff;
  }
}
@media screen and (min-width: 769px), print {
  #obiproject .obiproject_task {
    display: flex;
    max-width: 1040px;
    margin: 0 auto 35px;
  }
}
@media screen and (max-width: 768px), print {
  #obiproject .obiproject_task {
    margin-bottom: 30px;
  }
}
#obiproject .obiproject_task li {
  margin-bottom: 6px;
}
@media screen and (min-width: 769px), print {
  #obiproject .obiproject_task li {
    width: 33.333%;
  }
  #obiproject .obiproject_task li:nth-child(2) {
    margin: 0 3px;
  }
}
@media screen and (min-width: 769px), print {
  #obiproject .obiproject_task + p {
    width: 640px;
    display: block;
    margin: 0 auto;
  }
}
#obiproject .obiproject_experience {
  background: url(../img/common/mat.jpg);
}
@media screen and (min-width: 769px), print {
  #obiproject .obiproject_experience {
    max-width: 1040px;
    margin: 0 auto 35px;
    display: flex;
    padding: 35px 60px;
  }
}
@media screen and (min-width: 769px) and (min-width: 769px), print {
  #obiproject .obiproject_experience + p {
    width: 640px;
    display: block;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px), print {
  #obiproject .obiproject_experience {
    padding: 40px 5vw;
    background-size: 16px;
    margin-bottom: 32px;
  }
}
#obiproject .obiproject_experience dl {
  position: relative;
}
@media screen and (min-width: 769px), print {
  #obiproject .obiproject_experience dl {
    width: 46.957%;
  }
}
@media screen and (min-width: 769px), print {
  #obiproject .obiproject_experience dl.now {
    margin-right: 6%;
  }
}
@media screen and (max-width: 768px), print {
  #obiproject .obiproject_experience dl.now {
    padding-bottom: 48px;
  }
}
#obiproject .obiproject_experience dl.now:after {
  content: "";
  width: 12px;
  height: 16px;
  background: url(../img/common/icon_arrow.png) no-repeat top left;
  background-size: 12px;
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
@media screen and (min-width: 769px), print {
  #obiproject .obiproject_experience dl.now:after {
    display: none;
  }
}
#obiproject .obiproject_experience dl.future dt {
  color: #b70000;
}
@media screen and (max-width: 768px), print {
  #obiproject .obiproject_experience dl.future dt {
    display: none;
  }
}
#obiproject .obiproject_experience dl.future dd p {
  background: #fffcce;
  border-color: #bfbfbf;
  position: relative;
}
@media screen and (min-width: 769px), print {
  #obiproject .obiproject_experience dl.future dd p:after {
    content: "";
    width: 9px;
    height: 12px;
    background: url(../img/common/icon_arrow.png) no-repeat top left;
    background-size: 9px;
    position: absolute;
    top: 50%;
    left: -7.5%;
    transform: translate(0, -42%) rotate(-90deg);
  }
}
#obiproject .obiproject_experience dt {
  font-weight: bold;
  text-align: center;
}
@media screen and (min-width: 769px), print {
  #obiproject .obiproject_experience dt {
    margin-bottom: 25px;
    font-size: 18px;
  }
}
@media screen and (max-width: 768px), print {
  #obiproject .obiproject_experience dt {
    margin-bottom: 20px;
    font-size: 16px;
  }
}
#obiproject .obiproject_experience dd p {
  font-weight: bold;
  text-align: center;
  width: 100%;
  height: 88px;
  padding: 0 10px;
  background: #fff;
  border: 1px solid #333;
  display: table;
  margin-bottom: 8px;
}
@media screen and (min-width: 769px), print {
  #obiproject .obiproject_experience dd p {
    font-size: 15px;
  }
}
@media screen and (max-width: 768px), print {
  #obiproject .obiproject_experience dd p {
    font-size: 14px;
  }
}
#obiproject .obiproject_experience dd p span {
  display: table-cell;
  vertical-align: middle;
}
#obiproject .obiproject_experience dd p span span {
  display: inline-block;
}
@media screen and (min-width: 769px), print {
  #obiproject .obiproject_cultural {
    background: url(../img/obiproject/cultural_pc.png) no-repeat top center;
    background-size: cover;
    max-width: 1040px;
    height: 414px;
    margin: 0px auto 70px;
  }
  #obiproject .obiproject_cultural img {
    display: none;
  }
}
@media screen and (min-width: 769px) and (min-width: 769px), print {
  #obiproject .obiproject_cultural + p {
    width: 640px;
    display: block;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px), print {
  #obiproject .obiproject_cultural {
    padding-top: 10px;
    margin-bottom: 32px;
  }
}

#obiproject.en .headline,
#obiproject.en .lower_contents {
  letter-spacing: 0.04em;
}
#obiproject.en .headline h2,
#obiproject.en .lower_contents h2 {
  letter-spacing: 0.064em;
  font-weight: 700;
}
@media screen and (min-width: 769px), print {
  #obiproject.en .obiproject_cultural {
    background: url(../img/obiproject/cultural_pc_en.png) no-repeat top center;
  }
}

@media screen and (min-width: 769px), print {
  #obiproject.cn .obiproject_cultural {
    background: url(../img/obiproject/cultural_pc_cn.png) no-repeat top center;
  }
}

@media screen and (min-width: 769px), print {
  #obiproject.tw .obiproject_cultural {
    background: url(../img/obiproject/cultural_pc_tw.png) no-repeat top center;
  }
}

#about main {
  overflow: hidden;
}
@media screen and (min-width: 769px), print {
  #about .lower_firstview {
    background: url(../img/about/main_pc.jpg) no-repeat center;
    background-size: cover;
  }
}
@media screen and (max-width: 768px), print {
  #about .lower_firstview {
    background: url(../img/about/main_sp.jpg) no-repeat center;
    background-size: cover;
  }
}
@media screen and (max-width: 768px), print {
  #about .lower_firstview h1 {
    top: 40px;
  }
}
#about .lower_firstview .anchors {
  position: absolute;
  -webkit-overflow-scrolling: touch;
}
@media screen and (min-width: 769px), print {
  #about .lower_firstview .anchors {
    bottom: 100px;
    text-align: right;
    width: 100%;
    padding: 0 3.1vw;
    max-width: 1400px;
    margin: 0 auto;
    display: block;
    left: 0;
    right: 0;
  }
}
@media screen and (max-width: 768px), print {
  #about .lower_firstview .anchors {
    overflow: hidden;
    overflow-x: scroll;
    bottom: 70px;
    width: 100%;
  }
}
#about .lower_firstview .anchors ul {
  white-space: nowrap;
}
@media screen and (max-width: 768px), print {
  #about .lower_firstview .anchors ul {
    padding: 10px 0 10px 90px;
    width: 590px;
  }
}
#about .lower_firstview .anchors li {
  display: inline-block;
}
@media screen and (min-width: 769px), print {
  #about .lower_firstview .anchors li {
    margin-left: 1em;
  }
}
@media screen and (max-width: 768px), print {
  #about .lower_firstview .anchors li {
    margin-right: 1em;
  }
}
#about .lower_firstview .anchors a {
  font-size: 12px;
}
#about .headline {
  padding: 0 0 35px;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 769px), print {
  #about .headline {
    overflow: hidden;
    height: 743px;
    background: url(../img/about/about_img1_pc.jpg) no-repeat right top #fff;
    padding-left: 80px;
    padding-top: 230px;
    margin-bottom: 108px;
    position: relative;
  }
  #about .headline:after {
    content: "";
    width: 440px;
    background: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 0;
  }
}
#about .headline img {
  margin-bottom: 30px;
}
@media screen and (min-width: 769px), print {
  #about .headline img {
    display: none;
  }
}
@media screen and (min-width: 1400px), print {
  #about .headline h2 {
    left: 50%;
    margin-left: -570px;
  }
}
@media screen and (min-width: 769px), print {
  #about .headline h2 {
    text-align: left;
    margin-bottom: 45px;
    position: relative;
    z-index: 1;
  }
  #about .headline h2:after {
    right: auto;
    margin: 0;
  }
}
@media screen and (max-width: 768px), print {
  #about .headline h2 {
    margin-bottom: 50px;
  }
}
#about .headline h3 {
  font-weight: bold;
  margin-bottom: 0.8em;
  letter-spacing: 0.16em;
}
@media screen and (min-width: 1400px), print {
  #about .headline h3 {
    left: 50%;
    margin-left: -570px;
  }
}
@media screen and (min-width: 769px), print {
  #about .headline h3 {
    font-size: 24px;
    position: relative;
    z-index: 1;
  }
}
@media screen and (max-width: 768px), print {
  #about .headline h3 {
    font-size: 18px;
  }
}
#about .headline p {
  font-size: 14px;
  line-height: 1.8;
}
@media screen and (min-width: 1400px), print {
  #about .headline p {
    left: 50%;
    margin-left: -570px;
  }
}
@media screen and (min-width: 769px), print {
  #about .headline p {
    position: relative;
    z-index: 1;
    background: #fff;
    width: 320px;
  }
}
@media screen and (min-width: 769px), print {
  #about .lower_contents {
    margin: 0 3.1vw;
    letter-spacing: 0.05em;
    max-width: 1200px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 768px), print {
  #about .lower_contents {
    margin: 0 5vw;
  }
}
@media screen and (min-width: 769px), print {
  #about .lower_contents > p {
    line-height: 2;
  }
}
@media screen and (min-width: 769px), print {
  #about .lower_contents h2 {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 768px), print {
  #about .lower_contents h2 {
    margin-bottom: 45px;
  }
}
#about .lower_contents h3 {
  font-weight: bold;
  border: none;
  padding: 0;
  letter-spacing: 0.16em;
}
@media screen and (min-width: 769px), print {
  #about .lower_contents h3 {
    margin-bottom: 1.4em;
    font-size: 20px;
    text-align: center;
  }
}
@media screen and (max-width: 768px), print {
  #about .lower_contents h3 {
    margin-bottom: 0.8em;
    font-size: 18px;
  }
}
@media screen and (min-width: 769px), print {
  #about .about_img01 {
    height: 382px;
    background: url(../img/about/about_img2_pc.jpg) no-repeat left top;
    position: relative;
    margin-bottom: 115px;
  }
  #about .about_img01:after {
    content: "";
    width: 449px;
    background: #fff;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 0;
  }
  #about .about_img01 .slide_about {
    display: none;
  }
}
@media screen and (max-width: 768px), print {
  #about .about_img01 {
    margin-bottom: 70px;
    max-width: 100vw;
  }
  #about .about_img01 .slide_about {
    display: block;
    margin-left: -5vw;
    margin-right: -5vw;
    margin-bottom: 25px;
    max-width: 100vw;
    width: 100vw;
  }
  #about .about_img01 .slide_about div {
    border-right: 1px solid #fff;
  }
  #about .about_img01 .slide_about img {
    width: 100%;
  }
}
@media screen and (min-width: 769px), print {
  #about .about_img01 p {
    position: absolute;
    right: 60px;
    top: 116px;
    width: 325px;
    z-index: 1;
  }
}
#about .about_img02 {
  display: block;
}
@media screen and (min-width: 769px), print {
  #about .about_img02 {
    background: url(../img/about/about_img3_pc.jpg) no-repeat top center;
    height: 497px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 768px), print {
  #about .about_img02 {
    margin-bottom: 35px;
    margin-left: -5vw;
    margin-right: -5vw;
    width: 100vw;
  }
}
@media screen and (min-width: 769px), print {
  #about .about_img02 img {
    display: none;
  }
}
@media screen and (min-width: 769px), print {
  #about .about_text1 {
    width: 720px;
    margin: 0 auto;
    text-align: left;
  }
}
@media screen and (min-width: 769px), print {
  #about .about_img03 {
    margin: 60px 0 110px;
    background: url(../img/about/about_img4_pc.jpg) no-repeat top right;
    height: 261px;
    position: relative;
  }
  #about .about_img03:after {
    content: "";
    width: 414px;
    background: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 0;
  }
}
@media screen and (max-width: 768px), print {
  #about .about_img03 {
    margin: 25px 0 70px;
  }
}
#about .about_img03 img {
  margin-bottom: 25px;
}
@media screen and (min-width: 769px), print {
  #about .about_img03 img {
    display: none;
  }
}
@media screen and (min-width: 769px), print {
  #about .about_img03 p {
    width: 325px;
    position: absolute;
    left: 40px;
    top: 15px;
    z-index: 1;
  }
}
@media screen and (min-width: 769px), print {
  #about .about_highlights {
    display: flex;
    margin-bottom: 115px;
  }
}
@media screen and (max-width: 768px), print {
  #about .about_highlights {
    margin-bottom: 70px;
  }
}
#about .about_highlights .num {
  font-family: "Quicksand", sans-serif;
  font-size: 26px;
  font-weight: bold;
  width: 48px;
  height: 48px;
  border: 3px solid #666;
  display: block;
  text-align: center;
  line-height: 44px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
}
#about .about_highlights li {
  position: relative;
  padding-top: 70px;
}
@media screen and (min-width: 769px), print {
  #about .about_highlights li {
    width: 33.33%;
    padding-bottom: 243px;
  }
}
@media screen and (max-width: 768px), print {
  #about .about_highlights li {
    margin-bottom: 40px;
  }
}
#about .about_highlights li h3 {
  text-align: center;
  margin-bottom: 20px;
}
@media screen and (min-width: 769px), print {
  #about .about_highlights li p {
    margin: 0 40px;
    min-height: 310px;
  }
}
@media screen and (max-width: 768px), print {
  #about .about_highlights li p {
    margin: 0 5vw 35px;
  }
}
@media screen and (min-width: 769px), print {
  #about .about_highlights li img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
@media screen and (min-width: 769px), print {
  #about .about_highlights li:nth-child(2):before {
    content: "";
    width: 1px;
    top: 0;
    bottom: 0;
    background: #eee;
    position: absolute;
    left: 0;
    z-index: 1;
  }
  #about .about_highlights li:nth-child(2):after {
    content: "";
    width: 1px;
    top: 0;
    bottom: 0;
    background: #eee;
    position: absolute;
    right: 0;
    z-index: 1;
  }
}
#about .about_img04 {
  display: block;
}
@media screen and (min-width: 769px), print {
  #about .about_img04 {
    position: relative;
    max-width: 1040px;
    margin: 0 auto;
    height: 540px;
    background: url(../img/about/about_map_pc.png) no-repeat top center;
  }
}
@media screen and (max-width: 768px), print {
  #about .about_img04 {
    margin-left: -5vw;
    margin-right: -5vw;
    max-width: 100vw;
  }
}
@media screen and (min-width: 769px), print {
  #about .about_img04 img {
    display: none;
  }
}
#about .about_img04 a {
  display: block;
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 0;
}
@media screen and (max-width: 768px), print {
  #about .about_img04 a {
    display: none;
  }
}
#about .about_img04 a.anchor1 {
  margin-top: 174px;
  margin-left: -181px;
}
#about .about_img04 a.anchor2 {
  margin-top: 112px;
  margin-left: -164px;
}
#about .about_img04 a.anchor3 {
  margin-top: 102px;
  margin-left: -220px;
}
#about .about_img04 a.anchor4 {
  margin-top: 178px;
  margin-left: -110px;
}
#about .about_img04 a.anchor5 {
  margin-top: 284px;
  margin-left: -82px;
}
#about .about_img04 a.anchor6 {
  margin-top: 298px;
  margin-left: -163px;
}
#about .about_img04 a.anchor7 {
  margin-top: 54px;
  margin-left: 13px;
}
#about .about_img04 a.anchor8 {
  margin-top: 99px;
  margin-left: 33px;
}
#about .about_img04 a.anchor9 {
  margin-top: 115px;
  margin-left: -87px;
}
#about .about_img04 a.anchor10 {
  margin-top: 279px;
  margin-left: -21px;
}
#about .about_img04 a.anchor11 {
  margin-top: 338px;
  margin-left: -421px;
}
#about .about_img04 a.anchor12 {
  margin-top: 145px;
  margin-left: -9px;
}
#about .about_img04 a.anchor13 {
  margin-top: 1px;
  margin-left: 4px;
}
#about .about_building {
  display: block;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 47px;
}
@media screen and (min-width: 769px), print {
  #about .about_building {
    max-width: 1040px;
    margin: 48px auto 37px;
  }
}
@media screen and (max-width: 768px), print {
  #about .about_building {
    margin-top: 30px;
    margin-left: -5vw;
    margin-right: -5vw;
    max-width: 100vw;
  }
}
#about .about_building p {
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  margin-top: 10px;
}
@media screen and (min-width: 769px), print {
  #about .about_building p {
    border-bottom: 2px solid #d8d8d8;
  }
}
@media screen and (max-width: 768px), print {
  #about .about_building p {
    border-bottom: 1px solid #666;
  }
}
#about .about_building p.second {
  margin-top: 4px;
}
#about .about_building li {
  display: block;
  margin-bottom: 23px;
  position: relative;
}
@media screen and (min-width: 769px), print {
  #about .about_building li {
    width: 23.847%;
    margin-right: 1.53%;
    padding-top: 33px;
  }
}
@media screen and (max-width: 768px), print {
  #about .about_building li {
    padding-top: 20px;
    width: 50%;
  }
}
#about .about_building li a {
  display: block;
}
#about .about_building li a:after {
  content: "01";
  background: url(../img/about/icn_pick.png) no-repeat center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: bold;
}
@media screen and (min-width: 769px), print {
  #about .about_building li a:after {
    font-size: 24px;
    width: 51px;
    height: 59px;
    padding-top: 7px;
  }
}
@media screen and (max-width: 768px), print {
  #about .about_building li a:after {
    font-size: 18px;
    width: 40px;
    height: 46px;
    padding-top: 6px;
  }
}
@media screen and (min-width: 769px), print {
  #about .about_building li:nth-child(4n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 768px), print {
  #about .about_building li:nth-child(2n):before {
    content: "";
    position: absolute;
    left: -2px;
    width: 4px;
    background: #fff;
    top: 0;
    bottom: 0;
  }
}
#about .about_building li:nth-child(2) a:after {
  content: "02";
}
#about .about_building li:nth-child(3) a:after {
  content: "03";
}
#about .about_building li:nth-child(4) a:after {
  content: "04";
}
#about .about_building li:nth-child(5) a:after {
  content: "05";
}
#about .about_building li:nth-child(6) a:after {
  content: "06";
}
#about .about_building li:nth-child(7) a:after {
  content: "07";
}
#about .about_building li:nth-child(8) a:after {
  content: "08";
}
#about .about_building li:nth-child(9) a:after {
  content: "09";
}
#about .about_building li:nth-child(10) a:after {
  content: "10";
}
#about .about_building li:nth-child(11) a:after {
  content: "11";
}
#about .about_building li:nth-child(12) a:after {
  content: "12";
}
#about .about_building li:nth-child(13) a:after {
  content: "13";
}

#about.en .headline,
#about.en .lower_contents {
  letter-spacing: 0.04em;
}
#about.en .headline h2,
#about.en .lower_contents h2 {
  letter-spacing: 0.064em;
  font-weight: 700;
}
#about.en .headline h3,
#about.en .lower_contents h3 {
  letter-spacing: 0.05em;
  font-weight: 500;
}
@media screen and (min-width: 769px), print {
  #about.en .about_img04 {
    background: url(../img/about/about_map_pc_en.png) no-repeat top center;
  }
}

@media screen and (min-width: 769px), print {
  #about.cn .about_img04 {
    background: url(../img/about/about_map_pc_cn.png) no-repeat top center;
  }
}

@media screen and (min-width: 769px), print {
  #about.tw .about_img04 {
    background: url(../img/about/about_map_pc_tw.png) no-repeat top center;
  }
}

@media screen and (min-width: 769px), print {
  .spot .lower_firstview {
    background: url(../img/spot/food/main_pc.jpg) no-repeat center;
    background-size: cover;
  }
}
@media screen and (max-width: 768px), print {
  .spot .lower_firstview {
    background: url(../img/spot/food/main_sp.jpg) no-repeat center;
    background-size: cover;
  }
}
.spot .lower_firstview h1 span {
  color: #ff7f7f;
}
.spot .lower_contents.notitle {
  border-radius: 5px;
}
@media screen and (min-width: 769px), print {
  .spot .lower_contents.notitle {
    padding-top: 25px;
    margin-bottom: 94px;
  }
}
@media screen and (max-width: 768px), print {
  .spot .lower_contents.notitle {
    margin-bottom: 62px;
  }
}
@media screen and (min-width: 769px), print {
  .spot .lower_contents_inner {
    padding: 0px 6.2vw;
  }
}
.spot .lower_contents_inner .pagenation {
  padding: 0 14px;
}
.spot_select p {
  color: #999999;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  margin-bottom: 1em;
  line-height: 1;
}
@media screen and (min-width: 769px), print {
  .spot_select p {
    display: none;
  }
}
.spot_select dl {
  overflow: hidden;
  letter-spacing: 0.05em;
  overflow: hidden;
  border-radius: 3px;
}
@media screen and (min-width: 769px), print {
  .spot_select dl {
    text-align: center;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px), print {
  .spot_select dl {
    width: 200px;
    box-shadow: 0px 2px 0px 0px #cecece;
    margin: 0 auto 34px;
  }
}
.spot_select dl a {
  text-decoration: none;
  position: relative;
}
@media screen and (min-width: 769px), print {
  .spot_select dl a {
    display: inline-block;
    margin: 0 2.55vw;
    padding: 10px 0 7px;
  }
  .spot_select dl a:before {
    content: "";
    width: 100%;
    height: 2px;
    background: #d3d3d3;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: .25s;
  }
  .spot_select dl a:hover:before {
    height: 7px;
  }
  .spot_select dl a:after {
    content: "";
    width: 1px;
    height: 18px;
    background: #e5e5e5;
    position: absolute;
    right: -2.5vw;
    top: 50%;
    margin-top: -9px;
  }
  .spot_select dl a:last-child:after {
    display: none;
  }
}
@media screen and (max-width: 768px), print {
  .spot_select dl a {
    width: 100%;
    display: block;
  }
}
.spot_select dl a span {
  letter-spacing: 0.1em;
}
@media screen and (min-width: 769px), print {
  .spot_select dl a span {
    font-size: 18px;
    position: relative;
  }
  .spot_select dl a span:before {
    content: '“';
  }
  .spot_select dl a span:after {
    content: '”';
  }
}
@media screen and (max-width: 768px), print {
  .spot_select dl a span {
    font-weight: bold;
    border: 1px solid #1b1b1b;
    border-radius: 1px solid #1b1b1b;
    display: block;
    font-family: 14px;
    padding: 12px 16px;
  }
}
.spot_select dt {
  display: block;
  position: relative;
}
@media screen and (min-width: 769px), print {
  .spot_select dt {
    display: none;
  }
}
.spot_select dt:after {
  content: "";
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0, -2px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 3px 0 3px;
  border-color: #1b1b1b transparent transparent transparent;
}
.spot_select dt span {
  display: none;
  text-decoration: none;
  border: 1px solid #1b1b1b;
  border-radius: 1px solid #1b1b1b;
  font-family: 14px;
  font-weight: bold;
  padding: 12px 16px;
  width: 100%;
}
@media screen and (min-width: 769px), print {
  .spot_select dd {
    display: block !important;
  }
  .spot_select dd a {
    color: #a1a1a1;
    position: relative;
  }
  .spot_select dd a .icn_pdf {
    position: absolute;
    right: -22px;
    top: 0px;
  }
  .spot_select dd .spot_select_food {
    color: #ff7f7f;
  }
  .spot_select dd .spot_select_food:before {
    background: #ffe0e0;
  }
  .spot_select dd .spot_select_stay {
    color: #bd700d;
  }
  .spot_select dd .spot_select_stay:before {
    background: #e8bc84;
  }
  .spot_select dd .spot_select_see {
    color: #7fbfff;
  }
  .spot_select dd .spot_select_see:before {
    background: #c5e0ff;
  }
  .spot_select dd .spot_select_experience {
    color: #70c120;
  }
  .spot_select dd .spot_select_experience:before {
    background: #c0e1a8;
  }
}
@media screen and (max-width: 768px), print {
  .spot_select dd {
    display: none;
  }
  .spot_select dd a {
    position: relative;
  }
  .spot_select dd a .icn_pdf {
    position: absolute;
    right: 12px;
    top: 50%;
    width: 22px;
    transform: translate(0, -60%);
  }
}

#spot_food .spot_select dt .spot_select_food {
  display: block;
}
@media screen and (max-width: 768px), print {
  #spot_food .spot_select dd .spot_select_food {
    display: none;
  }
}

@media screen and (min-width: 769px), print {
  #spot_stay .lower_firstview {
    background: url(../img/spot/stay/main_pc.jpg) no-repeat center;
    background-size: cover;
  }
}
@media screen and (max-width: 768px), print {
  #spot_stay .lower_firstview {
    background: url(../img/spot/stay/main_sp.jpg) no-repeat center;
    background-size: cover;
  }
}
#spot_stay .lower_firstview h1 span {
  color: #bd700d;
}
#spot_stay .spot_select dt .spot_select_stay {
  display: block;
}
@media screen and (max-width: 768px), print {
  #spot_stay .spot_select dd .spot_select_stay {
    display: none;
  }
}
#spot_stay .spot_list li {
  border: 2px solid #bd700d;
}
#spot_stay .spot_list li h2 {
  color: #bd700d;
}
#spot_stay .spot_img_wrap span {
  background: #bd700d;
}

@media screen and (min-width: 769px), print {
  #spot_see .lower_firstview {
    background: url(../img/spot/see/main_pc.jpg) no-repeat center;
    background-size: cover;
  }
}
@media screen and (max-width: 768px), print {
  #spot_see .lower_firstview {
    background: url(../img/spot/see/main_sp.jpg) no-repeat center;
    background-size: cover;
  }
}
#spot_see .lower_firstview h1 span {
  color: #7fbfff;
}
#spot_see .spot_select dt .spot_select_see {
  display: block;
}
@media screen and (max-width: 768px), print {
  #spot_see .spot_select dd .spot_select_see {
    display: none;
  }
}
#spot_see .spot_list li {
  border: 2px solid #7fbfff;
}
#spot_see .spot_list li h2 {
  color: #7fbfff;
}
#spot_see .spot_img_wrap span {
  background: #7fbfff;
}

@media screen and (min-width: 769px), print {
  #spot_experience .lower_firstview {
    background: url(../img/spot/experience/main_pc.jpg) no-repeat center;
    background-size: cover;
  }
}
@media screen and (max-width: 768px), print {
  #spot_experience .lower_firstview {
    background: url(../img/spot/experience/main_sp.jpg) no-repeat center;
    background-size: cover;
  }
}
#spot_experience .lower_firstview h1 span {
  color: #70c120;
}
#spot_experience .spot_select dt .spot_select_experience {
  display: block;
}
@media screen and (max-width: 768px), print {
  #spot_experience .spot_select dd .spot_select_experience {
    display: none;
  }
}
#spot_experience .spot_list li {
  border: 2px solid #70c120;
}
#spot_experience .spot_list li h2 {
  color: #70c120;
}
#spot_experience .spot_img_wrap span {
  background: #70c120;
}

@media screen and (min-width: 769px), print {
  #spot .lower_firstview {
    background: url(../img/spot/main_pc.jpg) no-repeat center;
    background-size: cover;
  }
}
@media screen and (max-width: 768px), print {
  #spot .lower_firstview {
    background: url(../img/spot/main_sp.jpg) no-repeat center;
    background-size: cover;
  }
}

@media screen and (min-width: 769px), print {
  .spot_contents_title {
    max-width: 880px;
    margin: 35px auto 45px;
    position: relative;
    padding-bottom: 20px;
    border-bottom: 1px solid #333;
    overflow: hidden;
  }
}
@media screen and (max-width: 768px), print {
  .spot_contents_title {
    margin: 20px 0 0;
    margin-bottom: 25px;
    padding-bottom: 30px;
    border-bottom: 1px solid #333;
  }
}
.spot_contents_title h1 {
  font-weight: bold;
}
@media screen and (min-width: 769px), print {
  .spot_contents_title h1 {
    font-size: 24px;
    position: absolute;
    left: 0;
    top: 0;
    width: 63%;
  }
}
@media screen and (max-width: 768px), print {
  .spot_contents_title h1 {
    font-size: 16px;
    margin-bottom: 1em;
  }
}
.spot_contents_title dl {
  margin-bottom: 25px;
}
@media screen and (min-width: 769px), print {
  .spot_contents_title dl {
    float: right;
    width: 30%;
  }
}
.spot_contents_title dt {
  font-size: 10px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}
@media screen and (min-width: 769px), print {
  .spot_contents_title dd {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px), print {
  .spot_contents_title dd {
    font-size: 12px;
  }
}
.spot_contents_mainphoto {
  display: block;
  position: relative;
  background: url(../img/common/noimage.png) no-repeat;
  background-size: cover;
}
@media screen and (min-width: 768px), print {
  .spot_contents_mainphoto {
    width: 100%;
    height: 586px;
  }
}
@media screen and (max-width: 767px), print {
  .spot_contents_mainphoto {
    width: 100vw;
    height: 214px;
    left: -5vw;
  }
}
@media screen and (min-width: 769px), print {
  .spot_contents_text {
    max-width: 880px;
    margin: 0 auto;
  }
}
.spot_contents_slide {
  position: relative;
}
@media screen and (min-width: 769px), print {
  .spot_contents_slide {
    margin: 45px auto 25px;
    max-width: 880px;
  }
}
@media screen and (max-width: 768px), print {
  .spot_contents_slide {
    overflow: hidden;
    overflow-x: scroll;
    width: 100vw;
    margin: 30px 0;
    left: -5vw;
    -webkit-overflow-scrolling: touch;
  }
}
.spot_contents_slide ul {
  display: flex;
}
@media screen and (min-width: 769px), print {
  .spot_contents_slide ul {
    flex-wrap: wrap;
    width: 100% !important;
  }
}
@media screen and (min-width: 769px), print {
  .spot_contents_slide li {
    width: 32.5%;
    margin-right: 1.25%;
    margin-bottom: 25px;
    cursor: pointer;
  }
  .spot_contents_slide li:nth-child(3n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 768px), print {
  .spot_contents_slide li {
    width: 250px;
    margin: 0 7px;
  }
}
.spot_contents_slide li > a {
  display: block;
}
@media screen and (min-width: 769px), print {
  .spot_contents_slide_text {
    margin-top: 10px;
    font-size: 12px;
  }
}
.spot_contents_access {
  border: 2px solid #333;
  border-radius: 3px;
}
@media screen and (min-width: 769px), print {
  .spot_contents_access {
    padding: 40px 25px;
    margin: 0px auto 45px;
    max-width: 880px;
    display: flex;
  }
}
@media screen and (max-width: 768px), print {
  .spot_contents_access {
    padding: 30px 22px;
  }
}
.spot_contents_access h4 {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  padding: 0;
  border-left: none;
  letter-spacing: 0.05em;
}
.spot_contents_access b {
  font-size: 13px;
  font-weight: bold;
}
.spot_contents_access p {
  font-size: 13px;
}
@media screen and (min-width: 769px), print {
  .spot_contents_access_maps {
    width: 400px;
    margin-right: 25px;
  }
}
@media screen and (max-width: 768px), print {
  .spot_contents_access_maps {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 769px), print {
  .spot_contents_access_info {
    width: 400px;
  }
  .spot_contents_access_info p {
    margin-bottom: 1.5em;
  }
}
.spot_listparts {
  margin-top: 60px;
}
@media screen and (min-width: 769px), print {
  .spot_listparts h2 {
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 769px), print {
  .spot_listparts.btn.back {
    width: 320px;
  }
}
@media screen and (max-width: 768px), print {
  .spot_listparts.btn.back {
    width: 288px;
  }
}

#spot_map {
  margin-bottom: 1em;
}
@media screen and (min-width: 769px), print {
  #spot_map {
    width: 400px;
    height: 267px;
  }
  #spot_map iframe {
    width: 400px;
    height: 267px;
  }
}
@media screen and (max-width: 768px), print {
  #spot_map {
    width: 100%;
    height: 161px;
  }
  #spot_map iframe {
    width: 100%;
    height: 161px;
  }
}

@media screen and (min-width: 769px), print {
  #event .lower_firstview {
    background: url(../img/event/main_pc.jpg) no-repeat center;
    background-size: cover;
  }
}
@media screen and (max-width: 768px), print {
  #event .lower_firstview {
    background: url(../img/event/main_sp.jpg) no-repeat center;
    background-size: cover;
  }
}
#event .lower_contents {
  border-radius: 5px;
}
@media screen and (min-width: 769px), print {
  #event .lower_contents {
    margin-bottom: 94px;
  }
  #event .lower_contents.single {
    max-width: 880px;
    margin: 0 auto 94px;
  }
}
@media screen and (max-width: 768px), print {
  #event .lower_contents {
    padding-right: 5vw;
    padding-left: 5vw;
    margin-bottom: 62px;
  }
}
@media screen and (min-width: 769px), print {
  #event .lower_contents_inner {
    padding: 0px 6.2vw;
  }
}
@media screen and (min-width: 769px), print {
  #event .lower_contents_inner .pagenation {
    padding: 0 14px;
  }
}

.event_contents_mainphoto {
  display: block;
  position: relative;
  background: url(../img/common/noimage.png) no-repeat;
  background-size: cover;
}
@media screen and (min-width: 768px), print {
  .event_contents_mainphoto {
    width: 100%;
    height: 586px;
  }
}
@media screen and (max-width: 767px), print {
  .event_contents_mainphoto {
    width: 100%;
    height: 170px;
  }
}
@media screen and (min-width: 769px), print {
  .event_contents_title {
    max-width: 880px;
    margin: 35px auto 45px;
    position: relative;
    padding-bottom: 20px;
    border-bottom: 1px solid #333;
    overflow: hidden;
  }
}
@media screen and (max-width: 768px), print {
  .event_contents_title {
    margin: 20px 0 0;
    margin-bottom: 25px;
    padding-bottom: 30px;
    border-bottom: 1px solid #333;
  }
}
.event_contents_title h1 {
  font-weight: bold;
}
@media screen and (min-width: 769px), print {
  .event_contents_title h1 {
    font-size: 24px;
    position: absolute;
    left: 0;
    top: 0;
    width: 63%;
  }
}
@media screen and (max-width: 768px), print {
  .event_contents_title h1 {
    font-size: 16px;
    margin-bottom: 1em;
  }
}
.event_contents_title dl {
  margin-bottom: 25px;
  font-family: "Roboto", sans-serif;
}
@media screen and (min-width: 769px), print {
  .event_contents_title dl {
    float: right;
    width: 30%;
  }
}
.event_contents_title dt {
  font-size: 10px;
  font-weight: bold;
}
@media screen and (min-width: 769px), print {
  .event_contents_title dd {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px), print {
  .event_contents_title dd {
    font-size: 12px;
  }
}
.event_contents_title dd .end:before {
  content: " ~ ";
}
@media screen and (max-width: 768px), print {
  .event_contents .spot_contents_slide {
    left: -10vw;
  }
}
@media screen and (min-width: 769px), print {
  .event_contents .btn.back {
    width: 320px;
  }
}
@media screen and (max-width: 768px), print {
  .event_contents .btn.back {
    margin-top: 32px;
    width: 100%;
  }
}

@media screen and (min-width: 769px), print {
  #topics .lower_firstview {
    background: url(../img/topics/main_pc.jpg) no-repeat center;
    background-size: cover;
  }
}
@media screen and (max-width: 768px), print {
  #topics .lower_firstview {
    background: url(../img/topics/main_sp.jpg) no-repeat center;
    background-size: cover;
  }
}
#topics .lower_contents {
  border-radius: 5px;
}
@media screen and (min-width: 769px), print {
  #topics .lower_contents {
    margin-bottom: 94px;
  }
  #topics .lower_contents.single {
    max-width: 880px;
    margin: 0 auto 94px;
  }
}
@media screen and (max-width: 768px), print {
  #topics .lower_contents {
    padding-right: 5vw;
    padding-left: 5vw;
    margin-bottom: 62px;
  }
}
@media screen and (min-width: 769px), print {
  #topics .lower_contents_inner {
    width: 720px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px), print {
  #contact .lower_firstview h1 {
    top: 35px;
  }
}
#contact .lower_firstview .pagelinks {
  position: relative;
  text-align: center;
}
@media screen and (min-width: 769px), print {
  #contact .lower_firstview .pagelinks {
    position: absolute;
    right: 3.1vw;
    bottom: 100px;
  }
}
@media screen and (min-width: 1400px), print {
  #contact .lower_firstview .pagelinks {
    right: 50%;
    margin-right: -700px;
  }
}
@media screen and (max-width: 768px), print {
  #contact .lower_firstview .pagelinks {
    top: 35px;
  }
}
#contact .lower_firstview .pagelinks li {
  display: inline-block;
}
@media screen and (min-width: 769px), print {
  #contact .lower_firstview .pagelinks li {
    font-size: 12px;
    margin: 0 0 0 10px;
  }
}
@media screen and (max-width: 768px), print {
  #contact .lower_firstview .pagelinks li {
    margin: 32px 7px 0;
  }
}
#contact .lower_firstview .pagelinks a {
  color: #333;
}
@media screen and (min-width: 769px), print {
  #contact .lower_contents {
    max-width: 1040px;
    width: 100%;
    margin: 0 auto 88px;
    overflow: hidden;
  }
}
@media screen and (max-width: 768px), print {
  #contact .lower_contents {
    margin: 0 5vw 58px;
  }
}
#contact .lower_contents img {
  display: inline-block;
}
#contact .lower_contents p {
  line-height: 2;
}
#contact .lower_contents p b {
  margin-bottom: 5px;
  display: block;
}
#contact .lower_contents small {
  line-height: 2;
  font-size: 12px;
  display: block;
}
@media screen and (min-width: 769px), print {
  #contact .lower_contents .telpicture {
    width: 345px;
  }
}
@media screen and (max-width: 768px), print {
  #contact .lower_contents .telpicture {
    width: 234px;
  }
}
@media screen and (min-width: 769px), print {
  #contact .lower_contents .mb {
    margin-bottom: 2em;
  }
}
@media screen and (max-width: 768px), print {
  #contact .lower_contents .mb {
    margin-bottom: 1.5em;
  }
}
@media screen and (min-width: 769px), print {
  #contact .lower_contents .contact_form {
    overflow: hidden;
    width: 645px;
    margin: 0 auto;
  }
}
#contact .lower_contents .contact_form p span {
  font-size: 15px;
  margin-bottom: 5px;
  position: relative;
}
@media screen and (min-width: 769px), print {
  #contact .lower_contents .contact_form p span {
    display: inline-block;
    width: 35.3%;
    text-align: right;
    padding-right: 26px;
    vertical-align: top;
    line-height: 42px;
  }
}
@media screen and (max-width: 768px), print {
  #contact .lower_contents .contact_form p span {
    display: inline;
  }
}
#contact .lower_contents .contact_form p input[type=text], #contact .lower_contents .contact_form p textarea {
  border-radius: 3px;
  background: #eeeeee;
  font-size: 16px;
}
@media screen and (min-width: 769px), print {
  #contact .lower_contents .contact_form p input[type=text], #contact .lower_contents .contact_form p textarea {
    display: inline-block;
    width: 63.722%;
    padding: 5px 15px;
  }
}
@media screen and (max-width: 768px), print {
  #contact .lower_contents .contact_form p input[type=text], #contact .lower_contents .contact_form p textarea {
    padding: 5px 10px;
    width: 100%;
    display: block;
  }
}
@media screen and (min-width: 769px), print {
  #contact .lower_contents .contact_form p textarea {
    line-height: 1.6;
    height: 320px;
    padding: 15px 20px;
  }
}
@media screen and (max-width: 768px), print {
  #contact .lower_contents .contact_form p textarea {
    height: 100px;
    min-height: 240px;
  }
}
#contact .lower_contents .contact_form p .required {
  position: relative;
}
#contact .lower_contents .contact_form p .required:after {
  content: "(必須)";
  font-size: 12px;
  color: #cb1b45;
}
@media screen and (min-width: 769px), print {
  #contact .lower_contents .contact_form p .required:after {
    display: inline-block;
    position: relative;
    right: 0;
    margin-left: 5px;
  }
}
@media screen and (max-width: 768px), print {
  #contact .lower_contents .contact_form p .required:after {
    display: block;
    position: absolute;
    right: -3em;
    top: -4px;
  }
}
#contact .lower_contents .contact_form p .required.en:after {
  content: "*";
}
@media screen and (max-width: 768px), print {
  #contact .lower_contents .contact_form p .required.en:after {
    right: -1em;
  }
}
#contact .lower_contents .contact_form p .required.cn:after {
  content: "必需";
}
#contact .lower_contents .contact_form p .required.tw:after {
  content: "必填";
}
@media screen and (min-width: 769px), print {
  #contact .lower_contents .contact_form .submit {
    cursor: pointer;
    width: 100%;
    margin: 20px 0 40px;
  }
}
@media screen and (max-width: 768px), print {
  #contact .lower_contents .contact_form .submit {
    margin: 0 auto;
  }
}
@media screen and (min-width: 769px), print {
  #contact .lower_contents .caution {
    width: 100%;
    margin: 10px 0 0;
    padding: 30px 0;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
  }
}
@media screen and (max-width: 768px), print {
  #contact .lower_contents .caution {
    margin-top: 44px;
  }
}
@media screen and (min-width: 769px), print {
  #contact .lower_contents .contact_confirm {
    overflow: hidden;
    padding-bottom: 2px;
    width: 645px;
    margin: 50px auto 0;
  }
}
@media screen and (max-width: 768px), print {
  #contact .lower_contents .contact_confirm {
    margin-top: 2em;
    font-size: 15px;
  }
}
@media screen and (min-width: 769px), print {
  #contact .lower_contents .contact_confirm dl {
    display: flex;
  }
}
@media screen and (min-width: 769px), print {
  #contact .lower_contents .contact_confirm dt {
    width: 205px;
    text-align: right;
    padding-right: 30px;
    box-sizing: content-box;
  }
}
@media screen and (max-width: 768px), print {
  #contact .lower_contents .contact_confirm dt {
    font-weight: bold;
    margin-bottom: 0.8em;
  }
}
#contact .lower_contents .contact_confirm dd {
  margin-bottom: 1.5em;
}
@media screen and (min-width: 769px), print {
  #contact .lower_contents .contact_confirm dd {
    width: 410px;
  }
}
#contact .lower_contents .contact_confirm .maintext {
  margin-bottom: 35px;
}
#contact .lower_contents .contact_confirm .maintext dd {
  font-size: 14px;
}
@media screen and (min-width: 769px), print {
  #contact .lower_contents .contact_confirm .submit {
    cursor: pointer;
    width: 320px;
    margin: 0;
    float: right;
    line-height: 52px;
  }
}
@media screen and (max-width: 768px), print {
  #contact .lower_contents .contact_confirm .submit {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.1em;
    line-height: 48px;
    margin: 0 auto 16px;
  }
}
#contact .lower_contents .contact_confirm .submit.back {
  background: #434343;
}
@media screen and (min-width: 769px), print {
  #contact .lower_contents .contact_confirm .submit.back {
    width: 320px;
    float: left;
  }
}
@media screen and (min-width: 769px), print {
  #contact .lower_contents .contact_complete p {
    text-align: center;
  }
}
#contact .lower_contents .error {
  color: #cb1b45;
  line-height: 1.8;
  font-size: 12px;
  margin-top: 7px;
}
@media screen and (min-width: 769px), print {
  #contact .lower_contents .error {
    margin-left: 235px;
  }
}
#contact .lower_contents .error.message {
  font-size: 13px;
  font-weight: bold;
  border: 2px solid #cb1b45;
  display: block;
  padding: 12px 20px;
  margin-bottom: 30px;
  margin-top: 0;
}
@media screen and (min-width: 769px), print {
  #contact .lower_contents .error.message {
    width: 645px;
    margin: 0 auto 32px;
    text-align: center;
  }
}

@media screen and (min-width: 769px), print {
  #access .lower_firstview {
    background: url(../img/access/main_pc.jpg) no-repeat center;
    background-size: cover;
  }
}
@media screen and (max-width: 768px), print {
  #access .lower_firstview {
    background: url(../img/access/main_sp.jpg) no-repeat center;
    background-size: cover;
  }
}
@media screen and (min-width: 769px), print {
  #access .lower_contents {
    margin: 0 3.1vw;
  }
}
@media screen and (max-width: 768px), print {
  #access .lower_contents {
    margin: 0 5vw;
  }
}
#access .lower_contents p:first-child {
  margin-bottom: 2.5em;
}
@media screen and (min-width: 769px), print {
  #access .lower_contents p:first-child {
    text-align: center;
  }
}
#access .lower_contents .rootlinks {
  background: url(../img/access/mat.jpg);
  background-size: 32px;
  border-radius: 3px;
  margin-bottom: 62px;
}
@media screen and (min-width: 769px), print {
  #access .lower_contents .rootlinks {
    padding: 32px 50px 14px;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto 62px;
  }
}
@media screen and (max-width: 768px), print {
  #access .lower_contents .rootlinks {
    padding: 32px 17px;
    text-align: center;
  }
}
#access .lower_contents .rootlinks li {
  margin-bottom: 14px;
}
@media screen and (min-width: 769px), print {
  #access .lower_contents .rootlinks li {
    display: inline-block;
  }
}
@media screen and (max-width: 768px), print {
  #access .lower_contents .rootlinks li {
    display: block;
  }
  #access .lower_contents .rootlinks li:last-child {
    margin-bottom: 0;
  }
}
#access .lower_contents .rootlinks .btn {
  margin: 0 auto;
}
@media screen and (min-width: 769px), print {
  #access .lower_contents .rootlinks .btn {
    width: 280px;
  }
}
#access .lower_contents b {
  display: block;
  margin-bottom: 0.3em;
}
@media screen and (min-width: 769px), print {
  #access .lower_contents .mb {
    margin-bottom: 2em;
  }
}
@media screen and (max-width: 768px), print {
  #access .lower_contents .mb {
    margin-bottom: 1.5em;
  }
}
@media screen and (min-width: 769px), print {
  #access .lower_contents .end {
    margin-bottom: 5em;
  }
}
@media screen and (max-width: 768px), print {
  #access .lower_contents .end {
    margin-bottom: 3em;
  }
}
@media screen and (min-width: 769px), print {
  #access .lower_contents h3 {
    max-width: 720px;
    margin: 0 auto 2em;
  }
}
@media screen and (min-width: 769px), print {
  #access .lower_contents p {
    max-width: 720px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 769px), print {
  #access .map {
    display: block;
    max-width: 1200px;
    height: 490px;
    margin: 0 auto 96px;
  }
}
@media screen and (max-width: 768px), print {
  #access .map {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 769px), print {
  #group .lower_contents {
    max-width: 720px;
    margin: 0 auto 88px;
  }
}
@media screen and (max-width: 768px), print {
  #group .lower_contents {
    margin: 0 5vw;
  }
}
#group .lower_contents dl {
  margin-bottom: 2.5em;
}
#group .lower_contents dt {
  font-weight: bold;
  margin-bottom: 0.5em;
}
#group .lower_contents dd {
  line-height: 1.8;
}
#group .lower_contents dd p {
  margin-bottom: 1em;
}

#privacy .headline small {
  max-width: 720px;
  margin: 0 auto;
}
@media screen and (min-width: 769px), print {
  #privacy .headline {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 25px;
  }
}
#privacy .lower_contents {
  line-height: 1.8;
}
@media screen and (min-width: 769px), print {
  #privacy .lower_contents {
    max-width: 720px;
    margin: 0 auto 88px;
  }
}
@media screen and (max-width: 768px), print {
  #privacy .lower_contents {
    margin: 0 5vw 60px;
    padding: 0 5vw;
  }
}
#privacy .lower_contents .mb3em {
  margin-bottom: 3em;
}
#privacy .lower_contents b {
  font-weight: bold;
  padding-bottom: 0.3em;
  display: inline-block;
}

@media screen and (min-width: 769px), print {
  .not_found_page .lower_firstview {
    background: none !important;
    height: 159px !important;
  }
}
@media screen and (max-width: 768px), print {
  .not_found_page .lower_firstview {
    background: none !important;
    height: 93px !important;
  }
}
.not_found_page h1 {
  text-align: center;
  font-weight: normal;
  font-size: 30px;
  letter-spacing: 0.04em;
}
.not_found_page p.text {
  font-size: 14px;
  text-align: center;
}
@media screen and (min-width: 769px), print {
  .not_found_page p.text {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 768px), print {
  .not_found_page p.text {
    line-height: 1.86;
    margin-bottom: 58px;
  }
}

/* ------ venobox.css --------*/
.vbox-overlay *, .vbox-overlay *:before, .vbox-overlay *:after {
  -webkit-backface-visibility: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.vbox-overlay {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1040;
  -webkit-transform: translateZ(1000px);
  transform: translateZ(1000px);
  transform-style: preserve-3d;
  background: rgba(255, 255, 255, 0.7) !important;
}

/* ----- navigation ----- */
.vbox-title {
  width: 100%;
  height: 40px;
  float: left;
  text-align: center;
  line-height: 28px;
  font-size: 12px;
  padding: 6px 40px;
  overflow: hidden;
  position: fixed;
  display: none;
  left: 0;
  z-index: 1050;
}

.vbox-close {
  cursor: pointer;
  position: fixed;
  display: block;
  background-position: 10px center;
  overflow: hidden;
  font-size: 19px;
  line-height: 1;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1050;
  background-color: initial !important;
  color: #333 !important;
  border-bottom: 1px solid #333;
  width: 55px;
}
@media screen and (min-width: 769px), print {
  .vbox-close {
    bottom: 74px;
  }
}
@media screen and (max-width: 768px), print {
  .vbox-close {
    bottom: 20px;
  }
}
.vbox-close:after {
  content: "CLOSE";
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.vbox-num {
  cursor: pointer;
  position: fixed;
  left: 0;
  height: 40px;
  display: block;
  overflow: hidden;
  line-height: 28px;
  font-size: 12px;
  padding: 6px 10px;
  display: none;
  z-index: 1050;
}

/* ----- navigation ARROWS ----- */
.vbox-next, .vbox-prev {
  position: fixed;
  top: 50%;
  margin-top: -15px;
  overflow: hidden;
  cursor: pointer;
  display: block;
  z-index: 1050;
  background: #434343;
}
@media screen and (min-width: 769px), print {
  .vbox-next, .vbox-prev {
    width: 48px;
    height: 48px;
  }
}
@media screen and (max-width: 768px), print {
  .vbox-next, .vbox-prev {
    width: 35px;
    height: 35px;
  }
}

.vbox-next span, .vbox-prev span {
  border: 2px solid transparent;
  border-top-color: #fff !important;
  border-right-color: #fff !important;
  text-indent: -100px;
  position: absolute;
  top: 19px;
  display: block;
}
@media screen and (min-width: 769px), print {
  .vbox-next span, .vbox-prev span {
    width: 16px;
    height: 16px;
  }
}
@media screen and (max-width: 768px), print {
  .vbox-next span, .vbox-prev span {
    width: 10px;
    height: 10px;
  }
}

@media screen and (min-width: 769px), print {
  .vbox-prev {
    left: 50%;
    transform: translate(-424px, 0);
    margin-left: -24px;
  }
}
@media screen and (max-width: 768px), print {
  .vbox-prev {
    left: 0px;
  }
}

@media screen and (min-width: 769px), print {
  .vbox-next {
    left: 50%;
    transform: translate(424px, 0);
    margin-left: -24px;
  }
}
@media screen and (max-width: 768px), print {
  .vbox-next {
    right: 0px;
  }
}

.vbox-prev span {
  -ms-transform: rotate(-135deg) translate(-50%, -50%);
  -webkit-transform: rotate(-135deg) translate(-50%, -50%);
  transform: rotate(-135deg) translate(-50%, -50%);
}
@media screen and (min-width: 769px), print {
  .vbox-prev span {
    top: 4px;
    left: 20px;
  }
}
@media screen and (max-width: 768px), print {
  .vbox-prev span {
    top: 5px;
    left: 14px;
  }
}

.vbox-next span {
  -ms-transform: rotate(45deg) translate(-50%, -50%);
  -webkit-transform: rotate(45deg) translate(-50%, -50%);
  transform: rotate(45deg) translate(-50%, -50%);
}
@media screen and (min-width: 769px), print {
  .vbox-next span {
    top: 27px;
    right: 19px;
  }
}
@media screen and (max-width: 768px), print {
  .vbox-next span {
    right: 14px;
  }
}

/* ------- inline window ------ */
.vbox-inline {
  width: 420px;
  height: 315px;
  height: 70vh;
  padding: 10px;
  background: #fff;
  margin: 0 auto;
  overflow: auto;
  text-align: left;
}

/* ------- Video & iFrames window ------ */
.venoframe {
  max-width: 100%;
  width: 100%;
  border: none;
  width: 100%;
  height: 260px;
  height: 70vh;
}

.venoframe.vbvid {
  height: 260px;
}

@media (min-width: 768px) {
  .venoframe, .vbox-inline {
    width: 90%;
    height: 360px;
    height: 70vh;
  }

  .venoframe.vbvid {
    width: 640px;
    height: 360px;
  }
}
@media (min-width: 992px) {
  .venoframe, .vbox-inline {
    max-width: 1200px;
    width: 80%;
    height: 540px;
    height: 70vh;
  }

  .venoframe.vbvid {
    width: 960px;
    height: 540px;
  }
}
/* 
Please do NOT edit this part! 
or at least read this note: http://i.imgur.com/7C0ws9e.gif
*/
.vbox-open {
  overflow: hidden;
}

.vbox-container {
  position: fixed;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  z-index: 20;
  max-height: 100%;
  top: 50%;
  transform: translate(0, -50%);
}

.vbox-content {
  text-align: center;
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  position: relative;
}
@media screen and (min-width: 769px), print {
  .vbox-content {
    padding: 20px 10px;
  }
}
@media screen and (max-width: 768px), print {
  .vbox-content {
    padding: 20px 18px;
    display: block;
    z-index: 100;
  }
}

.vbox-container img {
  max-width: 720px;
  height: auto;
  display: inline-block;
}

.figlio {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  max-width: 100%;
  text-align: initial;
}

img.figlio {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.vbox-content.swipe-left {
  margin-left: -200px !important;
}

.vbox-content.swipe-right {
  margin-left: 200px !important;
}

.animated {
  webkit-transition: margin 300ms ease-out;
  transition: margin 300ms ease-out;
}

.animate-in {
  opacity: 1;
}

.animate-out {
  opacity: 0;
}

/* ---------- preloader ----------
 * SPINKIT 
 * http://tobiasahlin.com/spinkit/
-------------------------------- */
.sk-double-bounce, .sk-rotating-plane {
  width: 40px;
  height: 40px;
  margin: 40px auto;
}

.sk-rotating-plane {
  background-color: #333;
  -webkit-backface-visibility: visible;
  -moz-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation: sk-rotatePlane 1.2s infinite ease-in-out;
  animation: sk-rotatePlane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotatePlane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
@keyframes sk-rotatePlane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
.sk-double-bounce {
  position: relative;
}

.sk-double-bounce .sk-child {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-doubleBounce 2s infinite ease-in-out;
  animation: sk-doubleBounce 2s infinite ease-in-out;
}

.sk-double-bounce .sk-double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-doubleBounce {
  0%,100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-doubleBounce {
  0%,100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.sk-wave {
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  margin: 40px auto;
}

.sk-wave .sk-rect {
  background-color: #333;
  height: 100%;
  width: 4px;
  margin: 0 1px;
  display: inline-block;
  -webkit-animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
  animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
}

.sk-wave .sk-rect1 {
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s;
}

.sk-wave .sk-rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-wave .sk-rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-wave .sk-rect4 {
  -webkit-animation-delay: -.9s;
  animation-delay: -.9s;
}

.sk-wave .sk-rect5 {
  -webkit-animation-delay: -.8s;
  animation-delay: -.8s;
}

@-webkit-keyframes sk-waveStretchDelay {
  0%,100%,40% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes sk-waveStretchDelay {
  0%,100%,40% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
.sk-three-bounce {
  margin: 40px auto;
  width: 100px;
  text-align: center;
}

.sk-three-bounce .sk-child {
  width: 16px;
  height: 16px;
  background-color: #333;
  border-radius: 100%;
  margin: 4px;
  display: inline-block;
  -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}

.sk-cube-grid, .sk-spinner-pulse {
  width: 40px;
  height: 40px;
  margin: 40px auto;
}

.sk-three-bounce .sk-bounce1 {
  -webkit-animation-delay: -.32s;
  animation-delay: -.32s;
}

.sk-three-bounce .sk-bounce2 {
  -webkit-animation-delay: -.16s;
  animation-delay: -.16s;
}

@-webkit-keyframes sk-three-bounce {
  0%,100%,80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-three-bounce {
  0%,100%,80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.sk-spinner-pulse {
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-pulseScaleOut 1s infinite ease-in-out;
  animation: sk-pulseScaleOut 1s infinite ease-in-out;
}

@-webkit-keyframes sk-pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes sk-pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
.sk-cube-grid .sk-cube {
  width: 33.33%;
  height: 33.33%;
  background-color: #333;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: .2s;
  animation-delay: .2s;
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: .4s;
  animation-delay: .4s;
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: .1s;
  animation-delay: .1s;
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: .2s;
  animation-delay: .2s;
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: .1s;
  animation-delay: .1s;
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: .2s;
  animation-delay: .2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,100%,70% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
@keyframes sk-cubeGridScaleDelay {
  0%,100%,70% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
.sk-wandering-cubes {
  margin: 40px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-wandering-cubes .sk-cube {
  background-color: #333;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-wanderingCube 1.8s ease-in-out -1.8s infinite both;
  animation: sk-wanderingCube 1.8s ease-in-out -1.8s infinite both;
}

.sk-wandering-cubes .sk-cube2 {
  -webkit-animation-delay: -.9s;
  animation-delay: -.9s;
}

@-webkit-keyframes sk-wanderingCube {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  25% {
    -webkit-transform: translateX(30px) rotate(-90deg) scale(0.5);
    transform: translateX(30px) rotate(-90deg) scale(0.5);
  }
  50% {
    -webkit-transform: translateX(30px) translateY(30px) rotate(-179deg);
    transform: translateX(30px) translateY(30px) rotate(-179deg);
  }
  50.1% {
    -webkit-transform: translateX(30px) translateY(30px) rotate(-180deg);
    transform: translateX(30px) translateY(30px) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0) translateY(30px) rotate(-270deg) scale(0.5);
    transform: translateX(0) translateY(30px) rotate(-270deg) scale(0.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes sk-wanderingCube {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  25% {
    -webkit-transform: translateX(30px) rotate(-90deg) scale(0.5);
    transform: translateX(30px) rotate(-90deg) scale(0.5);
  }
  50% {
    -webkit-transform: translateX(30px) translateY(30px) rotate(-179deg);
    transform: translateX(30px) translateY(30px) rotate(-179deg);
  }
  50.1% {
    -webkit-transform: translateX(30px) translateY(30px) rotate(-180deg);
    transform: translateX(30px) translateY(30px) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0) translateY(30px) rotate(-270deg) scale(0.5);
    transform: translateX(0) translateY(30px) rotate(-270deg) scale(0.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
/* Slider */
.slide_wrap {
  margin: 0 auto;
}
@media screen and (max-width: 768px), print {
  .slide_wrap {
    width: 100%;
  }
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-prev, .slick-next {
  position: absolute;
  width: 28px;
  height: 28px;
  text-indent: 100%;
  white-space: nowrap;
  background: #fe1;
  background-size: cover;
  overflow: hidden;
  top: 50%;
  left: -55px;
  margin-top: -14px;
  cursor: pointer;
}
@media screen and (max-width: 768px), print {
  .slick-prev, .slick-next {
    display: none !important;
  }
}

.slick-next {
  left: auto;
  right: -55px;
  transform: rotate(180deg);
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-dots {
  position: absolute;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
  z-index: 3;
}
@media screen and (min-width: 769px), print {
  .slick-dots {
    bottom: 20px;
  }
}
@media screen and (max-width: 768px), print {
  .slick-dots {
    bottom: 15px;
  }
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
  background: #999;
}
@media screen and (min-width: 769px), print {
  .slick-dots li {
    height: 2px;
    width: 40px;
    margin: 5px;
  }
}
@media screen and (max-width: 768px), print {
  .slick-dots li {
    height: 1px;
    width: 24px;
  }
}
.slick-dots li.slick-active {
  background: #fff;
}
.slick-dots li.slick-active button {
  background: #fff;
}
.slick-dots li button {
  border: 0;
  background: #999;
  display: block;
  height: 2px;
  width: 100%;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}


